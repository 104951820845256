import React from "react";
import { Container, Row } from "reactstrap";
import styled from "styled-components";
//
import Column from "./Column";

const ContainerStyled = styled(Container)`
  .page--components {
    .container {
      padding: 0px;

      &.component--text {
        ul {
        }
      }
    }
  }
`;

export default (props) => {
  var setStyle = {};
  if (props.style) {
    props.style.split(';').map(item => {
      var sty = item.split(':')
      setStyle[sty[0]] = sty[1];
    });
  }

  return (
    <ContainerStyled className={[`component--${props.name}`, props.class]} style={props.setStyle} id={props.id}>
      <Row>
        {props.columns &&
          props.columns.map((column, idx) => {
            return <Column {...column} cols={props.columns.length} key={`key-${idx}`} />
          }
          )}
      </Row>
    </ContainerStyled>
  );
};
