import ClientApi from "../api/client";
import { useAuth } from "./useAuth";

export const useMessages = () => {
  const auth = useAuth();

  const getAll = () => {
    return ClientApi.get(`/api/messages/get/${auth.info.user.token}`, {
      headers: { Authorization: `Bearer ${auth.info.access_token}` },
    });
  };

  const send = (data, messages, setMessages, setDisplayModal) => {
    let request = data;
    request.token = auth.info.user.token;
    return ClientApi.post(`/api/messages/create`, request, { headers: { Authorization: `Bearer ${auth.info.access_token}` } })
      .then((x) => {
        setDisplayModal(false);
        const inbox = JSON.parse(JSON.stringify(messages));
        inbox.push(x.data.data[0]);
        setMessages(inbox);
      });
  };

  return { getAll, send };
};
