import React, { useState, useEffect } from 'react';
import './Affiliate.scss'
import { useForm } from "react-hook-form";
import { Input } from '../../components/Input';
import i18n from '../../components/i18n';
import { Button, Row, Col, Table, Alert, Modal } from 'react-bootstrap';
import { useAffiliate } from '../../hooks/useAffiliate';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLink, faFilter, faCheck } from '@fortawesome/free-solid-svg-icons';
import LoginAff from './Login';

const groupBy = function (xs, key) {
    return xs.reduce(function (rv, x) {
        (rv[x[key]] = rv[x[key]] || []).push(x);
        return rv;
    }, {});
};

const Affiliate = () => {
    const auth = useAffiliate();
    const { register, handleSubmit, errors } = useForm();
    const [userInfo, setUserInfo] = useState(auth.info);
    const [accounts, setAccounts] = useState([]);
    const [ftds, setCfds] = useState([]);
    const [country, setCountry] = useState([]);
    const defualtDate = {'from': '', 'until': '', 'where': 'created_at'};
    const [date, setDate] = useState(defualtDate);
    const [filterShow, setFilterShow] = useState(false);

    useEffect(() => {
        if (auth.info) {
            setUserInfo(auth.info);
            auth.GetAccounts(setAccounts);
        }
    }, [auth.info])


    useEffect(() => {
        if (accounts && accounts.length > 0) {
            const totalCfd = accounts.filter(item => item.transaction !== null);
            setCfds(totalCfd);

            const totalCountry = groupBy(accounts, 'country');
            setCountry(totalCountry);
        }
    }, [accounts])


    const onSubmitFilter = data => {
        setDate(data);
        auth.GetAccounts(setAccounts, data);
        setFilterShow(false);
    }

    const resetFilters = () => {
        auth.GetAccounts(setAccounts);
        setFilterShow(false);
        setDate(defualtDate);
    }

    const fields = ['id', 'first_name', 'last_name', 'email', 'country', 'created_at', 'source_page', 'transaction']
    return (
        <>
            {!userInfo && <LoginAff auth={auth} userInfo={userInfo} setUserInfo={setUserInfo} />}
            {userInfo &&
                <div className="affiliate-panel thm-container sec-pad">

                    <div className="d-flex">
                        <span>{userInfo.user.name} ({userInfo.user.email})</span>
                        <Button variant="warning" size="sm" onClick={() => auth.signOut()} className="ml-auto">Logout</Button>
                    </div>

                    <h1>{i18n.t('affiliate.panel.title')} <Button variant="light" size="sm" className="ml-3" onClick={() => setFilterShow(true)}><FontAwesomeIcon icon={faFilter} /></Button></h1>
                    <div>
                        <Row className="d-flex m-0">
                            <Col>
                                <Alert variant="info" className="text-center">
                                    <Alert.Heading>{accounts.length}</Alert.Heading>
                                    <div>Total</div>
                                </Alert>
                            </Col>
                            <Col>
                                <Alert variant="info" className="text-center">
                                    <Alert.Heading>
                                        {ftds.length}
                                    </Alert.Heading>
                                    <div>FTDs</div>
                                </Alert>
                            </Col>
                            <Col>
                                <Alert variant="info" className="text-center">
                                    <Alert.Heading>{(ftds.length > 0 && accounts.length > 0) && ((ftds.length / accounts.length) * 100).toFixed(0)}%</Alert.Heading>
                                    <div>Conversions</div>
                                </Alert>
                            </Col>
                        </Row>
                    </div>

                    <h4>By Country</h4>
                    <Table striped bordered hover responsive>
                        <thead>
                            <tr>
                                <th>Country</th>
                                <th>Total</th>
                                <th>FTDs</th>
                                <th>Conversions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {Object.keys(country).map((item) => {
                                const total = country[item].length;
                                const ftd = country[item].filter(item => item.transaction !== null).length;
                                return <tr key={item}>
                                    <td>{(item === 'null') ? 'Unknown' : item}</td>
                                    <td>{total}</td>
                                    <td>{ftd}</td>
                                    <td>{(total > 0 && ftd > 0) ? ((total / ftd) * 100).toFixed(0) + '%' : 0}</td>
                                </tr>
                            })}
                        </tbody>
                    </Table>
                    <h4>By Account</h4>
                    <Table striped bordered hover responsive>
                        <thead>
                            <tr>
                                {fields.map((item, i) => {
                                    return <th key={i}>{i18n.t(`affiliate.panel.table.${item}`)}</th>
                                })}
                            </tr>
                        </thead>
                        <tbody>
                            {accounts && accounts.map((item, i) => {
                                if(date.where === 'transactions' && item.transaction === null) {
                                    return false;
                                }
                                return <tr key={i}>
                                    <td>{item.id}</td>
                                    <td>{item.first_name}</td>
                                    <td>{item.last_name}</td>
                                    <td>{item.email}</td>
                                    <td>{(item.country === null) ? 'Unknown' : item.country}</td>
                                    <td>{item.created_at}</td>
                                    <td>{item.source_page && <a href={item.source_page} target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={faLink} /> Show</a>}</td>
                                    <td>{item.transaction}</td>
                                </tr>
                            })}
                        </tbody>
                    </Table>

                    <Modal
                        show={filterShow}
                        onHide={() => setFilterShow(false)}
                        className="filterModal"
                        >
                        <Modal.Header closeButton>
                            <Modal.Title id="example-modal-sizes-title-sm">Filter</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>

                            <form onSubmit={handleSubmit(onSubmitFilter)} noValidate>
                                <Row>
                                    <Col xs={3}>Filter by</Col>
                                    <Col xs={4}><label className="radioContainer">
                                        <input name="where" id="created_at" type="radio" value="created_at" ref={register({ required: true })} defaultChecked={date.where === 'created_at'} />
                                        <span className="checkmark"><FontAwesomeIcon icon={faCheck} />{i18n.t('affiliate.panel.created_at')}</span>
                                    </label></Col>
                                    <Col xs={4}><label className="radioContainer">
                                        <input name="where" id="transaction" type="radio" value="transactions" ref={register({ required: true })} defaultChecked={date.where === 'transactions'} />
                                        <span className="checkmark"><FontAwesomeIcon icon={faCheck} />{i18n.t('affiliate.panel.transaction')}</span>
                                    </label></Col>
                                </Row>
                                <Input name="from" type="date" defaultValue={date.from} reference={register({ required: true })} errors={errors} title={i18n.t('affiliate.panel.from.label')} placeholder={i18n.t('affiliate.panel.from.placeholder')} />
                                <Input name="until" type="date" defaultValue={date.until} reference={register({ required: true })} errors={errors} title={i18n.t('affiliate.panel.until.label')} placeholder={i18n.t('affiliate.panel.until.placeholder')} />
                                <div>
                                    <Row className="d-flex">
                                        <Col>
                                            <Button block className={`button`} type="submit">{i18n.t('affiliate.panel.search')}</Button>
                                        </Col>
                                        <Col>
                                            <Button block variant="light" className={`button`} onClick={() => resetFilters()}>{i18n.t('affiliate.panel.reset')}</Button>
                                        </Col>
                                    </Row>
                                </div>
                            </form>

                        </Modal.Body>
                    </Modal>

                </div>
            }
        </>
    );
}

export default Affiliate;