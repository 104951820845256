import ClientApi from "../api/client";
import { LoginRequest, UpdatePasswordRequest, GetAccountsFilterRequest } from "./models";
import { useState, useEffect } from "react";
import { EventEmitter } from "./EventEmitter";


export const useAffiliate = () => {
    const onLoginSuccess = new EventEmitter('login_success');
    const loginStatusChange = new EventEmitter('login_status_change');
    const [info, setInfo] = useState(localStorage.getItem('authAffiliate') ? JSON.parse(atob(localStorage.getItem('authAffiliate'))) : null);
    const onError = new EventEmitter('login_error');
    const uploadSuccess = new EventEmitter('upload_success');

    const isLoggedIn = (): boolean => {
        return (info !== null && info.accessToken !== null);
    };


    useEffect(() => {
        localStorage.setItem('authAffiliate', btoa(JSON.stringify(info)));
    }, [info]);


    const signOut = () => {
        setInfo(null);
        localStorage.removeItem('authAffiliate');
        loginStatusChange.emit(false);
        window.location.href = "/affiliate";
    }

    const signIn = (loginRequest: LoginRequest) => {
        ClientApi.post(`/api/affiliate/login`, loginRequest).then(x => {
            if (x.data.data.access_token !== undefined && info === null && x.data.status === true) {
                setInfo(x.data.data);
                loginStatusChange.emit(true);
            } else {
                onError.emit(x.data);
            }
            return x;
        }).catch(e => onError.emit(e))
    }

    const updatePassword = (PasswordRequest: UpdatePasswordRequest) => {
        ClientApi.post(`/api/affiliate/updatePassword`, PasswordRequest, { headers: { "Authorization": `Bearer ${info.access_token}` } }).then(x => {
            if (x.data.status === true) {
                // setShowNoti(true);
            } else {
                onError.emit(x.data);
            }
            return x;
        }).catch(e => onError.emit(e))
    }

    const GetAccounts = (setAccounts, filter?: GetAccountsFilterRequest) => {
        ClientApi.post(`/api/affiliate/get`, { token: info.user.token, ...filter }, { headers: { "Authorization": `Bearer ${info.access_token}` } })
            .then(x => {
                if (x && x.data) {
                    setAccounts(x.data.data);
                }
            })
            .catch(e => console.log(e))
    }

    return { signIn, isLoggedIn, loginStatusChange, updatePassword, onLoginSuccess, signOut, info, GetAccounts, onError, uploadSuccess }
}