import React from "react";
import { Container } from "reactstrap";
import styled from "styled-components";
import PageComponents from "../PageComponents";

const ContainerStyled = styled(Container)`
  .page--components {
    .container {
      padding: 0px;

      &.component--text {
        ul {
        }
      }
    }
  }
`;

export default (props) => {
  var setStyle = {};
  if (props.style) {
    props.style.split(';').map(item => {
      var sty = item.split(':')
      setStyle[sty[0]] = sty[1];
    });
  }
  return (
    <ContainerStyled className={[`component--${props.name}`, props.class]} style={setStyle} id={props.id}>
      {props.children &&
        <PageComponents components={props.children} />
      }
    </ContainerStyled>
  );
};
