import React, { useState, useEffect } from 'react';
import './Register.scss'
import { useForm } from "react-hook-form";
import { useAuth } from '../../hooks/useAuth';
import { Input } from '../../components/Input';
import i18n from '../../components/i18n';
import { Button, Row, Col } from 'react-bootstrap';
import { Link } from "react-router-dom";
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'
import { isValidPhoneNumber, isPossiblePhoneNumber, parsePhoneNumber } from 'react-phone-number-input'
import { useGet } from '../../hooks/helper';

const Register = () => {
    const auth = useAuth();
    const { register, handleSubmit, errors, setValue } = useForm();
    const [loadingButton, setLoadingButton] = useState(false);
    const [error, setError] = useState(false);
    const [phoneValid, setPhoneValid] = useState(false);
    const [phone, setPhone] = useState(null);
    const [userInfo, setUserInfo] = useState({ ip: '', country_short: '' });

    const get = useGet();

    useEffect(() => {
        register({ name: 'phone' }, { required: true });
    }, [])

    useEffect(() => {
        const info = get.userInfo;
        if (info) {
            setUserInfo(get.userInfo);
        }
    }, [get.userInfo])

    useEffect(() => {
        if (auth.info) {
            localStorage.setItem('register', 'true');
            window.location.href = '/my-account/info';
        }
    }, [auth.info])

    const onSubmit = data => {
        if (phoneValid) {
            setLoadingButton(true);
            if (data.phone && parsePhoneNumber(data.phone).country) {
                data.country = parsePhoneNumber(data.phone).country;
            }
            data.info = userInfo ? JSON.stringify(userInfo) : '';
            auth.signUp(data);
            auth.onError.subscribe(x => {
                if (!x.status) {
                    setLoadingButton(false);
                    setError(true);
                }
            })
        }
    }

    return (
        <>
            <div className="register thm-container sec-pad">
                <h1>{i18n.t('register.title')}</h1>
                <form onSubmit={handleSubmit(onSubmit)} noValidate>
                    <Row>
                        <Col xs={12} md={6}>
                            <Input name="first_name" type="text" reference={register({ required: true })} errors={errors} title={i18n.t('register.first_name.label')} placeholder={i18n.t('register.first_name.placeholder')} />
                        </Col>
                        <Col xs={12} md={6}>
                            <Input name="last_name" type="text" reference={register({ required: true })} errors={errors} title={i18n.t('register.last_name.label')} placeholder={i18n.t('register.last_name.placeholder')} />
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12} md={6}>
                            <Input name="email" type="email" reference={register({ required: true, pattern: { value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i, message: "invalid email address" } })} errors={errors} title={i18n.t('register.email.label')} placeholder={i18n.t('register.email.placeholder')} />
                        </Col>
                        <Col xs={12} md={6}>
                            <label className="field phone">
                                {i18n.t('register.phone.label')}
                                <PhoneInput
                                    placeholder="Enter phone number"
                                    value={phone}
                                    defaultCountry={userInfo.country_short}
                                    onChange={(number) => {
                                        setValue('phone', number);
                                        setPhone(number);
                                        if (isValidPhoneNumber(number) && isPossiblePhoneNumber(number)) {
                                            setPhoneValid(true);
                                        } else {
                                            setPhoneValid(false);
                                        }
                                    }} />
                                <span className="error">
                                    {(phone !== null && !phoneValid) ? i18n.t('register.phone.errorValid') : ''}
                                    {(errors.phone) ? i18n.t('register.phone.errorRequired') : ''}
                                </span>
                            </label>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12}>
                            <Input name="password" type="password" reference={register({ required: true })} errors={errors} title={i18n.t('register.password.label')} placeholder={i18n.t('register.password.placeholder')} />
                        </Col>
                        <Col xs={12}>
                            <label className="field" htmlFor="agree">
                                <input name="agree" id="agree" type="checkbox" ref={register({ required: true })} />
                                <span dangerouslySetInnerHTML={{ __html: i18n.t('register.agree.label') }}></span>
                                {errors.agree && <span className="error">{i18n.t('register.agree.errorRequired')}</span>}
                            </label>
                        </Col>
                    </Row>

                    <div>
                        <Button block className={`button ${loadingButton ? 'loading' : ''}`} disabled={loadingButton} type="submit">{i18n.t('register.button')}</Button>
                        <div className="error">{error && i18n.t('register.error_message')}</div>
                    </div>
                    <Row>
                        <Col className="text-left">{i18n.t('register.already_have_account')} <Link to='/login'>{i18n.t('register.login')}</Link></Col>
                    </Row>
                </form>
                <div className="reCAPTCHA">This site is protected by reCAPTCHA and the Google <a href="https://policies.google.com/privacy" target="_blank" rel="noopener noreferrer">Privacy Policy</a> and <a href="https://policies.google.com/terms" target="_blank" rel="noopener noreferrer">Terms of Service</a> apply.</div>
            </div>
        </>
    );
}
export default Register;