import React from 'react';
import ReactDOM from 'react-dom';
import 'bootstrap/dist/css/bootstrap.css';
import './App.scss';
import App from './App';
import * as Config from "./config";
import TagManager from 'react-gtm-module'
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';

if (Config.APP_NAME === 'UnitedCapitals') {
    document.documentElement.style.setProperty('--base', '#1f6ea0');
    document.documentElement.style.setProperty('--logo', 'url(assets/img/UnitedCapitals/logo.svg)');
    document.documentElement.style.setProperty('--logo-day', 'url(assets/img/UnitedCapitals/logo-day.png)');
}

    document.documentElement.setAttribute('color-mode', 'light');
// if (localStorage.getItem('theme-mode') && localStorage.getItem('theme-mode') === 'dark') {
//     document.documentElement.setAttribute('color-mode', 'dark');
// } else {
// }
const tagManagerArgs = {
    gtmId: Config.GTM_ID
}

TagManager.initialize(tagManagerArgs)

const rootNode = document.querySelector('#root')

ReactDOM.render(<GoogleReCaptchaProvider reCaptchaKey={Config.RECAPTCHA_KEY}><App /></GoogleReCaptchaProvider>, rootNode)
