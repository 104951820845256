import React, { useState } from 'react';
import { useForm } from "react-hook-form";
import { useAuth } from '../../../hooks/useAuth';
import { Input } from '../../../components/Input';
import i18n from '../../../components/i18n';
import { Button, Row, Col } from 'react-bootstrap';

const PasswordTab = ({ setShowNoti, auth }) => {
    const { register, handleSubmit, errors, watch } = useForm();
    const [loadingButton, setLoadingButton] = useState(false);

    const onSubmit = data => {
        setLoadingButton(true);
        data.token = auth.info.user.token;
        auth.updatePassword(data);
        setShowNoti(true);
        setLoadingButton(false);
    }

    return (
        <form onSubmit={handleSubmit(onSubmit)} noValidate className="changePassword">
            <Row>
                <Col xs={12}>
                    <Input name="new_password" type="text" defaultValue={auth.info.user.new_password} reference={register({ required: true, minLength: 8 })} minLength={8} errors={errors} title={i18n.t('myAccount.new_password.label')} placeholder={i18n.t('myAccount.new_password.placeholder')} />
                </Col>
                <Col xs={12}>
                    <Input name="confirm_password" type="text" defaultValue={auth.info.user.confirm_password} reference={
                        register({
                            validate: (value) => value === watch('new_password')
                        })
                    } errors={errors} title={i18n.t('myAccount.confirm_password.label')} placeholder={i18n.t('myAccount.confirm_password.placeholder')} />
                </Col>
            </Row>
            <div>
                <Button className={`button ${loadingButton ? 'loading' : ''}`} disabled={loadingButton} type="submit">{i18n.t('myAccount.button')}</Button>
            </div>
        </form>
    );
}
export default PasswordTab