import React, { createContext, useEffect, useState } from "react";
import { render } from "react-dom";
import { BrowserRouter, Switch, Route, Redirect, withRouter } from "react-router-dom";
import Home from "./pages/Home/Home";
import Page from "./pages/Page/Page";
import Unverified from "./pages/Page/Unverified";
import AccountTypes from "./pages/AccountTypes/AccountTypes";
import Platform from "./pages/Platform/Platform";
import Header from "./components/Header/Header";
import Footer from "./components/Footer/Footer";
import { fetchSingleton } from "./cockpit/fetch";
import Login from "./pages/Login/Login";
import ForgotPassword from "./pages/ForgotPassword/ForgotPassword";
import Register from "./pages/Register/Register";
import { Contact } from "./pages/Contact/Contact";
import { Payments } from "./pages/Payment/Payment";
import ScrollIntoView from "./hooks/ScrollIntoView";
import Affiliate from "./pages/Affiliate/Affiliate";
import { useAuth } from "./hooks/useAuth";

export const AppContext = createContext(null);

export default function App() {
  const [appContext, setAppContext] = useState(null);
  const auth = useAuth();

  useEffect(() => {
    let isCancelledSettings = false;

    fetchSingleton("Settings").then((pages) => {
      if (!isCancelledSettings) {
        setAppContext(pages);
      }
    });

    return () => {
      isCancelledSettings = true;
    };
  }, []);

  if (!localStorage.getItem("theme-mode")) {
    localStorage.setItem("theme-mode", "dark");
  }

  return (
    <BrowserRouter>
      <AppContext.Provider value={appContext}>
        <ScrollIntoView>
          <Header />
          <div className="main-container">
            <Switch>
              {!auth.isLoggedIn() ? (
                <Route exact path="/" component={Home} />
              ) : (
                <Route exact path="/" component={Home}>
                  <Redirect to="/platform/home" />
                </Route>
              )}

              <Route path="/login/unverified" component={Unverified} />
              <Route path="/login" component={Login} />
              <Route path="/password/request" component={ForgotPassword} />

              <Route path="/register" component={Register} />

              <Route path="/platform/:page/:slug" component={Platform} />
              <Route path="/platform/:page" component={Platform} />

              <Route exact path="/platform" component={Home}>
                <Redirect to="/platform/home" />
              </Route>

              <Route path="/payment/:method" component={Payments} />
              <Route exact path="/payment">
                <Redirect to="/payment/creditCard" />
              </Route>

              <Route path="/affiliate" component={Affiliate} />
              <Route path="/contact-us" component={Contact} />
              <Route path="/404" component={() => <div>404 Not found </div>} />
              <Route path="/types/:slug" component={AccountTypes} />
              <Route path="/:slug" component={Page} />
            </Switch>
          </div>
          <Footer />
        </ScrollIntoView>
      </AppContext.Provider>
    </BrowserRouter>
  );
}
const AppWithRouter = withRouter(App);

render(
  <BrowserRouter>
    <AppWithRouter />
  </BrowserRouter>,
  document.getElementById("root")
);
