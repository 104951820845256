import React from "react";
import Forms from "../../Forms/Forms"
import regexifyString from "regexify-string"

export default (props) => {

  return <>
    {
    (props.html.indexOf("[form-") === 0) ?
    <Forms type={props.html} /> :
    <div dangerouslySetInnerHTML={{__html: props.html }} className={props.class}></div>
    }
    </>
};