import React, { useState } from 'react';
import { useForm } from "react-hook-form";
import { Input } from '../../components/Input';
import i18n from '../../components/i18n';
import { Button } from 'react-bootstrap';

const LoginAff = ({auth, userInfo, setUserInfo}) => {
    const { register, handleSubmit, errors } = useForm();
    const [loadingButton, setLoadingButton] = useState(false);
    const [error, setError] = useState(false);

    const onSubmitLogin = data => {
        setLoadingButton(true);
        data.info = userInfo ? JSON.stringify(userInfo) : '';
        auth.signIn(data);
        auth.onError.subscribe(x => {
            if (!x.status) {
                setLoadingButton(false);
                setError(true);
            }
        })
    }
    return(
        <div className="affiliate-login-page thm-container sec-pad">
        <h1>{i18n.t('login.title')}</h1>
        <form onSubmit={handleSubmit(onSubmitLogin)} noValidate>
            <Input name="email" type="email" reference={register({ required: true, pattern: { value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i, message: "invalid email address" } })} errors={errors} title={i18n.t('login.email.label')} placeholder={i18n.t('login.email.placeholder')} />
            <Input name="password" type="password" reference={register({ required: true })} errors={errors} title={i18n.t('login.password.label')} placeholder={i18n.t('login.password.placeholder')} />
            <div>
                <Button block className={`button ${loadingButton ? 'loading' : ''}`} disabled={loadingButton} type="submit">{i18n.t('login.button')}</Button>
                <div className="error">{error && i18n.t('login.error_message')}</div>
            </div>
        </form>
    </div>
    );
}
export default LoginAff;