import React, { useState, useEffect } from "react";
import { getPageFromStorage } from "../../cockpit/fetch";
import { useParams } from "react-router-dom";
import * as Config from "../../config";
import "./AccountTypes.scss";
import CountUp from "react-countup";
import { Container, Row, Button, Col } from "reactstrap";
import { Link } from "react-router-dom";

const AccountTypes = () => {
  require("../../websites/" + Config.APP_NAME + "/style.scss");
  const [page, setPage] = useState(null);
  const [monthlyPerformance, setMonthlyPerformance] = useState(null);
  const [tabDisplay, setTabDisplay] = useState(0);
  const [amount, setAmount] = useState(10000);
  const [growth, setGrowth] = useState(25);
  const [months, setMonths] = useState(18);

  const { slug } = useParams();

  useEffect(() => {
    let isCancelled = false;
    getPageFromStorage(
      slug,
      (data) => {
        if (!isCancelled) {
          setPage(data);
          setMonthlyPerformance(JSON.parse(data.MonthlyPerformance.split("\u21b5").join("").replace(/\s/g, "").toString()));
        }
      },
      "accountType"
    );
    return () => {
      isCancelled = true;
    };
  }, [slug]);

  const CompoundCalculator = () => {
    const result = amount * Math.pow(1 + growth / (1 * 100), 1 * months);
    return Number(result).toFixed(0);
  };
  const [oldFutureValue, setOldFutureValue] = useState(CompoundCalculator());

  return (
    <>
      {page && (
        <>
          <div className={`page-${page.title.toLowerCase()}`}>
            <div className="header-page">
              <div className="container">
                <h1>{page.title}</h1>
              </div>
            </div>
            <div className="strategy-wrapper">
              <div className="strategy-sticky-block">
                <h2>{page.title}</h2>
                <h3>{page.Description}</h3>
                <ul>
                  <li>
                    <span>Total Return</span> <strong className="green">{page.TotalReturn}</strong>
                  </li>
                  <li>
                    <span>Average Monthly ROI</span> <strong className="green">{page.AverageMonthlyROI}</strong>
                  </li>
                  <li>
                    <span>Max Drawdown</span> <strong>{page.MaxDD}</strong>
                  </li>
                </ul>
                <Link to="/login">
                  <Button outline block type="submit" color="primary">
                    Invest in Managed Account
                  </Button>
                </Link>
                <div className="disclamer">{page.Disclamer}</div>
              </div>

              <div className="strategy-description">
                <div className="description">
                  <div className="title">Verified Trading History</div>
                  <ul>
                    <li>
                      <strong>Total Return</strong>
                      <strong className="green">{page.TotalReturn}</strong>
                    </li>
                    <li>
                      <strong>Average Monthly ROI</strong>
                      <strong className="green">{page.AverageMonthlyROI}</strong>
                    </li>
                    <li>
                      <strong>Max Drawdown</strong>
                      <strong>{page.MaxDD}</strong>
                    </li>
                    <li>
                      <strong>Trading live since</strong>
                      <strong className="green">{page.TradingLiveSince}</strong>
                    </li>
                  </ul>
                </div>

                <div className="monthlyPerformance">
                  <div className="title">Monthly performance</div>
                  <div className="tab-wrapper">
                    {monthlyPerformance &&
                      monthlyPerformance.map((item, i) => {
                        const pre = item.value > 0 ? "+" : "";
                        return (
                          <div key={i} className={`tab ${tabDisplay === i ? "active" : ""}`} onClick={() => setTabDisplay(i)}>
                            <strong>{item.year}</strong>
                            <span className={`value ${item.value >= 0 ? "green" : "red"}`}>
                              {pre}
                              {item.value === 0 ? "Development" : item.value + "%"}
                            </span>
                          </div>
                        );
                      })}
                  </div>

                  <div className="tab-content-wrapper">
                    {monthlyPerformance &&
                      monthlyPerformance.map((item, i) => (
                        <div key={i} className={`tab-content ${tabDisplay === i ? "active" : ""}`}>
                          {item.months &&
                            Object.keys(item.months).map((key, index) => {
                              const pre = item.months[key] > 0 ? "+" : "";
                              return (
                                <div key={index} className="line">
                                  <strong>{key}</strong>
                                  <span className={item.months[key] >= 0 ? "green" : "red"}>
                                    {item.months[key] === 0 ? "/" : pre + item.months[key] + "%"}
                                  </span>
                                </div>
                              );
                            })}
                        </div>
                      ))}
                  </div>
                </div>

                <div className="brokerInfo">
                  <div className="title">Apply for Managed Trading Account</div>
                  <ul>
                    <li>
                      <strong>Broker</strong> {page.Broker}
                    </li>
                    <li>
                      <strong>Country</strong> {page.Country}
                    </li>
                    <li>
                      <strong>Registration</strong> {page.Registration}
                    </li>
                    <li>
                      <strong>Licence</strong> {page.Licence}
                    </li>
                    <li>
                      <strong>Equity protection</strong> {page.EquityProtection}
                    </li>
                    <li>
                      <strong>Operating since</strong> {page.OperatingSince}
                    </li>
                    <li>
                      <strong>Clients funds</strong> {page.ClientsFunds}
                    </li>
                  </ul>
                </div>

                <div className="fees">
                  <div className="title">Minimum Deposit & Fees</div>
                  <ul>
                    <li>
                      <strong>Minimum deposit</strong> {page.MinimumDeposit}
                    </li>
                    <li>
                      <strong>Performance fee</strong> {page.PerformanceFee}
                    </li>
                  </ul>
                </div>

                <div className="strategyDescription">
                  <div className="title">Strategy Description</div>
                  <div className="content" dangerouslySetInnerHTML={{ __html: page.StrategyDescription }} />
                </div>

                <div className="compoundCalculator">
                  <div className="title">Plan Your Investment</div>
                  <div className="sub-title">
                    Compounding calculation, for demonstration purpose only. Past performance is no guarantee of future results.
                  </div>

                  <div className="CompoundCalculator">
                    <div className="title">Compound Calculator</div>
                    <div className="line">
                      <label htmlFor="amount">Initial Investment Amount:</label>
                      <Row>
                        <Col xs={11}>
                          <input type="number" id="amount" min="10000" defaultValue={amount} onChange={(e) => setAmount(Number(e.target.value))} />
                        </Col>
                        <Col>€</Col>
                      </Row>
                    </div>

                    <div className="line">
                      <label htmlFor="growth">
                        Average Monthly Growth (%): <br />
                        {growth}%
                      </label>
                      <Row>
                        <Col>
                          <input
                            type="range"
                            min="1"
                            max="50"
                            step="0.1"
                            className="slider"
                            defaultValue={growth}
                            value={growth}
                            id="growth"
                            onChange={(e) => setGrowth(Number(e.target.value))}
                          />

                          <div className="rc-slider-mark">
                            <span
                              className={`rc-slider-mark-text ${growth >= 10 ? "active" : ""}`}
                              style={{ left: "20%" }}
                              onClick={() => setGrowth(10)}
                            >
                              10
                            </span>
                            <span
                              className={`rc-slider-mark-text ${growth >= 20 ? "active" : ""}`}
                              style={{ left: "39%" }}
                              onClick={() => setGrowth(20)}
                            >
                              20
                            </span>
                            <span
                              className={`rc-slider-mark-text ${growth >= 30 ? "active" : ""}`}
                              style={{ left: "59%" }}
                              onClick={() => setGrowth(30)}
                            >
                              30
                            </span>
                            <span
                              className={`rc-slider-mark-text ${growth >= 40 ? "active" : ""}`}
                              style={{ left: "79%" }}
                              onClick={() => setGrowth(40)}
                            >
                              40
                            </span>
                            <span
                              className={`rc-slider-mark-text ${growth >= 50 ? "active" : ""}`}
                              style={{ left: "98%" }}
                              onClick={() => setGrowth(50)}
                            >
                              50
                            </span>
                          </div>
                        </Col>
                      </Row>
                    </div>

                    <div className="line">
                      <label htmlFor="months">Number of months the money is invested:</label>
                      <Row>
                        <Col xs={9}>
                          <input type="number" min="1" id="months" defaultValue={months} onChange={(e) => setMonths(Number(e.target.value))} />
                        </Col>
                        <Col>months</Col>
                      </Row>
                    </div>

                    <div className="result">
                      <strong>The future value of the investment:</strong>
                      <CountUp
                        end={Number(CompoundCalculator())}
                        start={Number(oldFutureValue)}
                        separator=","
                        decimals={0}
                        prefix="€ "
                        onEnd={() => setOldFutureValue(CompoundCalculator())}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};
export default AccountTypes;
