import React from "react";

const Page404 = () => (
  <div className="error-page sec-pad thm-container">
    <h1>Oops!</h1>
    <h2>We can't seem to find the page<br /> you're looking for.</h2>
    <h3>Error code: 404</h3>
  </div>
);

export default Page404;
