import React from "react";
import i18n from "../../../components/i18n";

import "./Contracts.scss";

export const Contracts = () => {
  return (
    <div className="contracts">
      <div className="header-platform">
        <h1>{i18n.t("contracts.title")}</h1>
      </div>
    </div>
  );
};
