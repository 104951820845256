import React, { useState, useEffect } from "react";
import "./MyAccount.scss";
import { Link } from "react-router-dom";
import i18n from "../../../components/i18n";
import { useGet } from "../../../hooks/helper";
import { Notification } from "../../../components/Notification/Notification";
import InfoTab from "./Info";
import PasswordTab from "./Password";
import DocumentsTab from "./Documents";
import { useParams } from "react-router-dom";

export const MyAccount = ({auth}) => {
  const { slug } = useParams();
  const get = useGet();
  const countries = get.countries;
  const [showNoti, setShowNoti] = useState(false);
  const [myCountry, setMyCounty] = useState();
  const pages = ["info", "password", "documents"];
  const [tab, setTab] = useState(pages.includes(slug) ? slug : "info");

  useEffect(() => {
    let isCancelled = false;

    if (!isCancelled) {
      let MC = countries ? countries.filter((data) => data.id === auth.info.user.country_id) : "";
      MC = MC.length > 0 ? MC[0].name : "";
      setMyCounty(MC);
    }

    return () => {
      isCancelled = true;
    };
  }, [countries]);
  return (
    <div className="my-account">
      <div className="header-platform">
        <h1>{i18n.t("myAccount.title")}</h1>
      </div>

      <div className="wrapper-page">
        <h2 className="name">
          {auth.info.user.first_name} {auth.info.user.last_name}
        </h2>

        <div className="userInformation">
          <div className="email">
            <strong>Email:</strong>
            {auth.info.user.email}
          </div>
          <div className="location">
            <strong>Location:</strong>
            {myCountry}
          </div>
          <div className="joined">
            <strong>Joined at:</strong>
            {auth.info.user.created_at}
          </div>
          <div className="joined">
            <strong>Status:</strong>
            {auth.info.user.verified_account ? "Verified" : "Not Verified"}
          </div>
          <div className="joined">
            <strong>Level:</strong>
            {auth.info.user.level}
          </div>
        </div>

        <ul className="tabs">
          {pages.map((item, i) => {
            return (
              <li key={i} className={tab === item ? "active" : ""}>
                <Link to={`/platform/my-account/${item}`} onClick={() => setTab(item)}>
                  {i18n.t(`myAccount.tabs.${item}`)}
                </Link>
              </li>
            );
          })}
        </ul>
      </div>

      <div className="card">
        <div className="card-body">
          {tab === "info" && <InfoTab setShowNoti={setShowNoti} auth={auth} useGet={get} />}
          {tab === "password" && <PasswordTab setShowNoti={setShowNoti} auth={auth} />}
          {tab === "documents" && <DocumentsTab setShowNoti={setShowNoti} auth={auth} />}
        </div>
      </div>
      <Notification show={showNoti} setShow={setShowNoti} title="Alert" text="The data was successfully saved" />
    </div>
  );
};
