import React, { useEffect, useState } from "react";
import "./style.scss";
import * as Config from "../../config";
import { getPageFromStorage } from "../../cockpit/fetch";
import PageComponents from "../../components/cockpit/PageComponents";
import NewsTicker from "react-advanced-news-ticker";

const Index = () => {
  const [page, setPage] = useState(null);

  useEffect(() => {
    let isCancelled = false;

    getPageFromStorage("index", (data) => {
      if (!isCancelled) {
        setPage(data);
      }
    });

    return () => {
      isCancelled = true;
    };
  }, []);

  useEffect(() => {
    if (document.getElementById("chart")) {
      const script = document.createElement("script");
      script.src = "https://s3.tradingview.com/external-embedding/embed-widget-market-quotes.js";
      script.async = true;
      script.innerHTML = JSON.stringify({
        width: "100%",
        height: 500,
        symbolsGroups: [
          {
            name: "Crypto",
            originalName: "Crypto",
            symbols: Config.symbolsGroupsCrypto,
          },
          {
            name: "Forex",
            originalName: "Forex",
            symbols: Config.symbolsGroupsForex,
          },
          {
            name: "Stocks",
            originalName: "Stocks",
            symbols: Config.symbolsGroupsStocks,
          },
          {
            name: "Commodities",
            originalName: "Commodities",
            symbols: Config.symbolsGroupsCommodities,
          },
        ],
        colorTheme: "light",
        isTransparent: true,
        locale: "en",
        largeChartUrl: `${Config.WEBSITE_SITE_ROOT}/platform/`,
      });
      document.getElementById("chart")!.appendChild(script);
    }
  }, [document.getElementById("chart")]);

  return (
    <>
      <div className="home-page">{page && <PageComponents components={page.components || []} />}</div>
    </>
  );
};
export default Index;
