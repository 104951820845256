import React, { useState } from 'react';
import { useForm } from "react-hook-form";
import { useAuth } from '../../../hooks/useAuth';
import i18n from '../../../components/i18n';
import { Row, Col, Card } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMapMarkedAlt, faPassport, faCreditCard } from '@fortawesome/free-solid-svg-icons';

export const UploadInput = ({ field, name, auth }) => {
    const { register, handleSubmit, reset } = useForm();
    const [status, setStatus] = useState(false);
    const [error, setError] = useState(null);
    const [loadingButton, setLoadingButton] = useState(false);

    const onSubmit = data => {
        setError(null);
        setLoadingButton(true);
        data.token = auth.info.user.token;
        data.type = field;
        auth.uploadDocuments(data);
        auth.uploadSuccess.subscribe(e => {
            setStatus(true);
            setLoadingButton(false);
            auth.GetData();
        });
        auth.onError.subscribe(e => {
            setError('File not supported');
            setLoadingButton(false);
            reset();
        })
    }

    return (<>
        {!status ?
            <form onSubmit={handleSubmit(onSubmit)} noValidate className="input-group" encType="multipart/form-data">
                <div className="input-group-prepend">
                    <button className={`input-group-text button ${loadingButton ? 'loading' : ''}`} disabled={loadingButton} type="submit">Upload</button>
                </div>
                <div className="custom-file">
                    <input
                        type="file"
                        className="custom-file-input"
                        name="files"
                        ref={register({ required: true })}
                    />
                    <label className="custom-file-label" htmlFor="inputGroupFile01">Choose {name}</label>
                </div>
                {loadingButton && <div className="loading">Loading...</div>}
            </form>
            : <span className="status Processing">Processing</span>
        }
        {error !== null && <span className="status Error">{error}</span>}
    </>);
}

const DocumentsTab = ({ setShowNoti, auth }) => {
    const docStatus = ['Waiting', 'Processing', 'Approved', 'Rejected'];
    const template = JSON.stringify({ "status": '0', "note": null, "updated": null });
    const passport = JSON.parse(auth.info.user.doc_passport !== null ? auth.info.user.doc_passport : template);
    const address = JSON.parse(auth.info.user.doc_address !== null ? auth.info.user.doc_address : template);
    const funding_front = JSON.parse(auth.info.user.doc_funding_front !== null ? auth.info.user.doc_funding_front : template);
    const funding_back = JSON.parse(auth.info.user.doc_funding_back !== null ? auth.info.user.doc_funding_back : template);

    return (
        <div className="documents">
            <Row>
                <Col xs={12}>
                    <Card className="mb-5">
                        <Card.Header as="h5"><FontAwesomeIcon icon={faPassport} /> {i18n.t('myAccount.documents.passport.title')}</Card.Header>
                        <Card.Body dangerouslySetInnerHTML={{ __html: i18n.t('myAccount.documents.passport.description') }}></Card.Body>
                        <Card.Footer>
                            {(passport['status'] === '0' || passport['status'] === '3') && <UploadInput field="doc_passport" name="Passport" auth={auth} />}
                            {passport['status'] !== '0' && <span className={`status ${docStatus[passport['status']]}`}>{docStatus[passport['status']]}</span>}
                            {passport['status'] === '3' && <span className="note">{passport['note']}</span>}
                        </Card.Footer>
                    </Card>
                </Col>
                <Col xs={12}>
                    <Card className="mb-5">
                        <Card.Header as="h5"><FontAwesomeIcon icon={faMapMarkedAlt} />  {i18n.t('myAccount.documents.address.title')}</Card.Header>
                        <Card.Body dangerouslySetInnerHTML={{ __html: i18n.t('myAccount.documents.address.description') }}></Card.Body>
                        <Card.Footer>
                            {(address['status'] === '0' || address['status'] === '3') && <UploadInput field="doc_address" name="Address" auth={auth} />}
                            {address['status'] !== '0' && <span className={`status ${docStatus[address['status']]}`}>{docStatus[address['status']]}</span>}
                            {address['status'] === '3' && <span className="note">{address['note']}</span>}
                        </Card.Footer>
                    </Card>
                </Col>
                <Col xs={12}>
                    <Card className="mb-5">
                        <Card.Header as="h5"><FontAwesomeIcon icon={faCreditCard} />  {i18n.t('myAccount.documents.funding.title')}</Card.Header>
                        <Card.Body dangerouslySetInnerHTML={{ __html: i18n.t('myAccount.documents.funding.description') }}></Card.Body>
                        <Card.Footer>
                            <Row>
                                <Col xs={12} md={6}>
                                    {(funding_front['status'] === '0' || funding_front['status'] === '3') && <UploadInput field="doc_funding_front" name="Front" auth={auth} />}
                                    {funding_front['status'] !== '0' && <span className={`status ${docStatus[funding_front['status']]}`}>{docStatus[funding_front['status']]}</span>}
                                    {funding_front['status'] === '3' && <span className="note">{funding_front['note']}</span>}
                                </Col>
                                <Col xs={12} md={6}>
                                    {(funding_back['status'] === '0' || funding_back['status'] === '3') && <UploadInput field="doc_funding_back" name="Back" auth={auth} />}
                                    {funding_back['status'] !== '0' && <span className={`status ${docStatus[funding_back['status']]}`}>{docStatus[funding_back['status']]}</span>}
                                    {funding_back['status'] === '3' && <span className="note">{funding_back['note']}</span>}
                                </Col>
                            </Row>
                        </Card.Footer>
                    </Card>
                </Col>
            </Row>
        </div>
    );
}
export default DocumentsTab