import React, { useState, useEffect } from "react";
import "./Inbox.scss";
import i18n from "../../../components/i18n";
import { useMessages } from "../../../hooks/useMessages";
import { Button, Row, Col, Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { Input } from "../../../components/Input";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSyncAlt } from "@fortawesome/free-solid-svg-icons";

export const Inbox = () => {
  const { register, handleSubmit, errors, reset } = useForm();
  const Mess = useMessages();
  const [messages, setMessages] = useState([]);
  const [loading, setLoading] = useState(true);
  const [displayModal, setDisplayModal] = useState(false);

  useEffect(() => {
    let isCancelled = false;
    Mess.getAll().then((data) => {
      if (!isCancelled) {
        setLoading(false);
        setMessages(data.data);
      }
    });

    return () => {
      isCancelled = true;
    };
  }, []);

  const NoData = () => {
    return (
      <div className="card">
        <div className="card-body">{i18n.t("inbox.no_messages")}</div>
      </div>
    );
  };

  const Loading = () => {
    return (
      <div className="text-center">
        <FontAwesomeIcon icon={faSyncAlt} spin size="lg" className="spinner" />
      </div>
    );
  };

  const onSubmitMessage = (data) => {
    Mess.send(data, messages, setMessages, setDisplayModal);
  };
  return (
    <>
      <div className="inbox">
        <div className="header-platform">
          <h1>{i18n.t("inbox.title")}</h1>
        </div>
        <div className="card">
          <div className="card-body">
            <Row className="d-flex" style={{ alignItems: "center" }}>
              <Col>
                <strong>Agent:</strong> Jone Smith
              </Col>
              <Col>
                <strong>Email:</strong> <a href="mailto:j.smith@uc.com">j.smith@uc.com</a>
              </Col>
              <Col>
                <strong>Phone:</strong> <a href="tel:123456789">123456789</a>
              </Col>
              <Col>
                <Button className="m-0" onClick={() => setDisplayModal(true)}>
                  Send message
                </Button>
              </Col>
            </Row>
          </div>
        </div>

        {messages.length > 0 ? (
          messages.sort((a, b) => b.id - a.id)
          .map((item, i) => {
            return (
              <div className="card" key={i}>
                <div className="card-header">
                  <div className="name">
                    <strong>From:</strong>{" "}
                    {item.direction_id === 2 ? (
                      <>
                        {item.agent_first_name} {item.agent_last_name}
                      </>
                    ) : (
                      "You"
                    )}
                  </div>
                  <div className="date">
                    <strong>At:</strong> {item.created_at}
                  </div>
                </div>
                <div className="card-body">
                  <div dangerouslySetInnerHTML={{ __html: item.notes }}></div>
                </div>
              </div>
            );
          })
        ) : (
          <>
          {loading ? <Loading /> : <NoData />}
          </>
        )}
      </div>

      <Modal show={displayModal} onHide={() => setDisplayModal(false)} className="sendMessageModal">
        <Modal.Header closeButton>
          <Modal.Title>{i18n.t("inbox.modal.title")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={handleSubmit(onSubmitMessage)} noValidate>
            <Input
              name="message"
              type="textarea"
              reference={register({ required: true })}
              errors={errors}
              title={i18n.t("inbox.modal.message.label")}
              placeholder={i18n.t("inbox.modal.message.placeholder")}
            />
            <div>
              <Row className="d-flex">
                <Col>
                  <Button block className={`button`} type="submit">
                    {i18n.t("inbox.modal.send")}
                  </Button>
                </Col>
                <Col>
                  <Button
                    block
                    variant="light"
                    className={`button`}
                    onClick={() => {
                      setDisplayModal(false);
                      reset();
                    }}
                  >
                    {i18n.t("inbox.modal.cancel")}
                  </Button>
                </Col>
              </Row>
            </div>
          </form>
        </Modal.Body>
      </Modal>
    </>
  );
};
