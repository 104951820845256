import React, { useState, useEffect } from 'react';
import { useForm } from "react-hook-form";
import { Input } from '../../../components/Input';
import i18n from '../../../components/i18n';
import { Button, Row, Col, Alert } from 'react-bootstrap';
import { formatDate } from "../../../hooks/helper";
import PhoneInput from 'react-phone-number-input'
import { isValidPhoneNumber, isPossiblePhoneNumber } from 'react-phone-number-input'
import { useHistory } from 'react-router';

const InfoTab = ({ setShowNoti, auth, useGet }) => {
    const get = useGet;
    const countries = get.countries;
    const { register, handleSubmit, errors, setValue } = useForm();
    const [loadingButton, setLoadingButton] = useState(false);
    const [phone, setPhone] = useState(auth.info.user.phone);
    const [phoneValid, setPhoneValid] = useState(isValidPhoneNumber(phone) && isPossiblePhoneNumber(phone));
    const history = useHistory();

    register({ name: 'phone', defaultValue: auth.info.user.phone, required: true });

    const onSubmit = data => {
        setLoadingButton(true);
        data.token = auth.info.user.token;
        auth.update(data);
    }

    useEffect(() => {
        let isCancelledUpdate = false;
        auth.onUpdateSuccess.subscribe(() => {
            if (localStorage.getItem('register') === 'true') {
                history.push('/payment/deposit');
            } else {
                if (!isCancelledUpdate) {
                    setShowNoti(true);
                    setLoadingButton(false);
                }
            }
        });

        return () => {
            isCancelledUpdate = true;
        }
    }, [auth])

    return (
        <form onSubmit={handleSubmit(onSubmit)} noValidate>
            {auth.missingDetails() && <Row>
                <Col xs={12}>
                    <Alert variant='warning'>{i18n.t('myAccount.errorMissingDetails')}</Alert>
                </Col>
            </Row>}
            <Row>
                <Col xs={12} sm={6}>
                    <Input name="first_name" type="text" defaultValue={auth.info.user.first_name} reference={register({ required: true })} errors={errors} title={i18n.t('myAccount.first_name.label')} placeholder={i18n.t('myAccount.first_name.placeholder')} />
                </Col>
                <Col xs={12} sm={6}>
                    <Input name="last_name" type="text" defaultValue={auth.info.user.last_name} reference={register({ required: true })} errors={errors} title={i18n.t('myAccount.last_name.label')} placeholder={i18n.t('myAccount.last_name.placeholder')} />
                </Col>
            </Row>
            <Row>
                <Col xs={12} sm={6}>
                    <label className="field phone">
                        {i18n.t('register.phone.label')}
                        <PhoneInput
                            placeholder="Enter phone number"
                            name="phone"
                            id="phone"
                            value={auth.info.user.phone}
                            onChange={(number) => {
                                setValue('phone', number);
                                setPhone(number);
                                if (isValidPhoneNumber(number) && isPossiblePhoneNumber(number)) {
                                    setPhoneValid(true);
                                } else {
                                    setPhoneValid(false);
                                }
                            }} />
                        <span className="error">
                            {(phone !== null && !phoneValid) ? i18n.t('register.phone.errorValid') : ''}
                            {(errors.phone) ? i18n.t('register.phone.errorRequired') : ''}
                        </span>
                    </label>
                </Col>
                <Col xs={12} sm={6}>
                    <Input name="birth_date" type="date" defaultValue={(auth.info.user.birth_date) ? formatDate(auth.info.user.birth_date) : ''} reference={register({ required: true })} errors={errors} title={i18n.t('myAccount.birth_date.label')} placeholder={i18n.t('myAccount.birth_date.placeholder')} />
                </Col>
            </Row>
            <Row>
                <Col xs={12} sm={6}>
                    <label className="field" htmlFor="country_id">
                        {i18n.t('myAccount.country.label')}
                        <select name="country_id" id="country_id" value={auth.info.user.country_id} ref={register({ required: true })} onChange={() => { }}>
                            {countries && countries.map((item) => {
                                return <option key={item.id} value={item.id}>{item.name}</option>
                            })}
                        </select>
                    </label>
                </Col>
                <Col xs={12} sm={6}>
                    <Input name="state" type="text" defaultValue={auth.info.user.state} reference={register({ required: true })} errors={errors} title={i18n.t('myAccount.state.label')} placeholder={i18n.t('myAccount.state.placeholder')} />
                </Col>
            </Row>
            <Row>
                <Col xs={12} sm={4}>
                    <Input name="city" type="text" defaultValue={auth.info.user.city} reference={register({ required: true })} errors={errors} title={i18n.t('myAccount.city.label')} placeholder={i18n.t('myAccount.city.placeholder')} />
                </Col>
                <Col xs={12} sm={4}>
                    <Input name="street" type="text" defaultValue={auth.info.user.street} reference={register({ required: true })} errors={errors} title={i18n.t('myAccount.street.label')} placeholder={i18n.t('myAccount.street.placeholder')} />
                </Col>
                <Col xs={12} sm={4}>
                    <Input name="zip_code" type="text" defaultValue={auth.info.user.zip_code} reference={register({ required: true })} errors={errors} title={i18n.t('myAccount.zip_code.label')} placeholder={i18n.t('myAccount.zip_code.placeholder')} />
                </Col>
            </Row>
            <div>
                <Button className={`button ${loadingButton ? 'loading' : ''}`} disabled={loadingButton} type="submit">{i18n.t('myAccount.button')}</Button>
            </div>
        </form>
    );
}
export default InfoTab;