import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import "./Summary.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowUp } from "@fortawesome/free-solid-svg-icons";
import { GetBalance, Formating } from "../../../hooks/helper";
import i18n from "../../../components/i18n";
import { Deposit } from "../../../components/Modals/Deposit/Deposit";

const Summary = () => {
  const [showDeposit, setShowDeposit] = useState(false);
  const balance = GetBalance();

  return (
    <>
      <div className="header-platform Summary">
        <div className="balance">
          <strong>{i18n.t("platform.balance")}</strong>
          <div>{Formating(balance.summary.balance, "€")}</div>
        </div>
        <div className="equity">
          <strong>{i18n.t("platform.equity")}</strong>
          <div>{Formating(balance.equity, "€")}</div>
        </div>
        <div className="free_margin">
          <strong>{i18n.t("platform.free_margin")}</strong>
          <div>{Formating(balance.free_margin, "€")}</div>
        </div>
        <div className="profit">
          <strong>{i18n.t("platform.profit")}</strong>
          <div>{Formating(balance.profit, "€")}</div>
        </div>
        <div className="actions">
          <Button className="deposit" onClick={() => setShowDeposit(true)}>
            <FontAwesomeIcon icon={faArrowUp} /> {i18n.t("platform.deposit")}
          </Button>
        </div>
      </div>
      <Deposit modalIsVisible={showDeposit} setModalIsVisible={setShowDeposit} />
    </>
  );
};

export default Summary;
