import React from "react";
import { Container } from "reactstrap";
import styled from "styled-components";

const ContainerStyled = styled(Container)`
  padding: 1rem 15px;
`;

export default (props) => {
  const Tag = styled(props.tag)`padding: 1rem 15px;`;
  return (
    <Tag className={[`component--${props.name}`, props.class]} style={props.setStyle} id={props.id}>
      {props.text}
    </Tag>
  );
};
