import React from 'react';

export const Input = (props: { name: string, reference: any, defaultValue?: string, title?, placeholder?, onChange?, type?, onFocus?, maxLength?, minLength?, errors?, onClick?, hideTitle?}) => {
    const setTrim = (e) => {
        let input = document.getElementById(props.name);
        (input as HTMLInputElement).value = e.trim();
    }
    let value;
    if(props.type === 'checkbox' || props.type ==='radio') {
        value = props.title;
    }
    const nonLabel = (props.type !== undefined && props.type !== 'hidden' && props.type !== 'checkbox');
    return <>
        <label className={`field ${props.name.replace('.', '_')}`}>
            {(nonLabel && !props.hideTitle) && <span className="title">{props.title}</span>}
            {props.type === 'textarea' ? (
                <textarea onChange={props.onChange} name={props.name} id={props.name} defaultValue={props.defaultValue} ref={props.reference} placeholder={props.placeholder} onFocus={props.onFocus} maxLength={props.maxLength} minLength={props.minLength} onClick={props.onClick} autoComplete={`new-${props.name}`}>{value}</textarea>
            ) : (
                <input type={props.type !== undefined ? props.type : 'text'} onChange={props.onChange} name={props.name} id={props.name} defaultValue={props.defaultValue} ref={props.reference} placeholder={props.placeholder} onFocus={props.onFocus} maxLength={props.maxLength} minLength={props.minLength} onClick={props.onClick} autoComplete={`new-${props.name}`} onBlur={(e) => setTrim(e.target.value)} value={value} />
            )}
            {(props.errors && props.errors[props.name] && props.errors[props.name].type !== undefined) && <>
                {(props.errors[props.name].type === 'required') && <span className="error">{props.title} is required</span>}
                {(props.errors[props.name].type === 'pattern') && <span className="error">{props.errors[props.name].message}</span>}
                {(props.errors[props.name].type === 'minLength') && <span className="error">{props.title} has minimum {props.minLength} characters</span>}
                {(props.errors[props.name].type === 'maxLength') && <span className="error">{props.title} has maximum {props.maxLength} characters</span>}
                {(props.errors[props.name].type === 'validate') && <span className="error">The passwords do not match</span>}
            </>}
        </label>
    </>
}