import React from "react";
import "./Forms.scss"
import QuestionnaireForm from "./QuestionnaireForm"
import ContactUs from "./ContactUs"
import Newsletter from "./Newsletter"

const Forms = (props) => {
  return (
    <>
      {props.type === "[form-QuestionnaireForm]" && <QuestionnaireForm />}
      {props.type === "[form-Newsletter]" && <Newsletter />}
      {props.type === "[form-ContactUs]" && <ContactUs />}
    </>
  );
};

export default Forms;
