import React, { useState, useEffect } from 'react';
import { Row, Col } from 'react-bootstrap';
import { Deposit } from '../../../components/Modals/Deposit/Deposit';
import { useHistory } from 'react-router';

export const DepositPage = () => {
    const [showDeposit, setShowDeposit] = useState(true);
    let history = useHistory();

    useEffect(()=>{
        localStorage.removeItem('register');
        if(!showDeposit) {
            history.push('/platform');
        }
    },[showDeposit])
    return (
        <div className="depositPage">
            <Row>
                <Col xs={12}>
                        <Deposit modalIsVisible={showDeposit} setModalIsVisible={setShowDeposit} modalType='page' />
                        <img src="/assets/img/deposit-icons.png" className="icons" alt="" />
                </Col>
            </Row>
        </div>
    );
}