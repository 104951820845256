import React from "react";
//
import {
  Heading,
  Text,
  Divider,
  CustomImage,
  BigHero,
  PageBanner,
  SectionDivider,
  LeafletMap,
  Grid,
  BlogPosts,
  PageBannerMedia,
  Html,
  Form,
  Video,
  SubPages,
  Button,
  CustomCarousel,
  Card,
  Image,
  Section,
  Ticker
} from ".";

const defaultComponents = {
  heading: Heading,
  text: Text,
  divider: Divider,
  customimage: CustomImage,
  bighero: BigHero,
  pagebanner: PageBanner,
  sectiondivider: SectionDivider,
  leafletmap: LeafletMap,
  grid: Grid,
  blogposts: BlogPosts,
  pagebannermedia: PageBannerMedia,
  html: Html,
  form: Form,
  video: Video,
  subpages: SubPages,
  button: Button,
  customcarousel: CustomCarousel,
  card: Card,
  image: Image,
  section: Section,
  ticker: Ticker
};

export default ({ components }) => (
  <>
    {components &&
      components.map((component_parsed, idx) => {
        const name = component_parsed.component.toLowerCase();
        const settings = component_parsed.settings;
        const columns = component_parsed.columns || [];
        const children = component_parsed.children || [];

        if (defaultComponents[name] === undefined) {
          return null;
        }
        const Component = defaultComponents[name];

        return (
          <Component
            name={name}
            {...settings}
            children={children}
            columns={columns}
            key={`component-${idx}`}
          />
        );
      })}
  </>
);
