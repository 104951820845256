import React from "react";

const Unverified = () => (
  <div className="unverified sec-pad thm-container">
    <h1>Your Account is Unverified!</h1>
    <h2>Blah Blah Blah.</h2>
  </div>
);

export default Unverified;
