import axios from "axios";
import * as Config from "../config";

const ClientApi = axios.create({
    baseURL: Config.ROUTER_HOST,
    headers: {
      'Access-Control-Allow-Origin' : '*',
    }
  });
  
export default ClientApi;