import React, { useState, useEffect } from "react";
import "./Positions.scss";
import i18n from "../../../components/i18n";
import { Table } from "react-bootstrap";
import { Formating } from "../../../hooks/helper";
import Chart from "react-google-charts";

const filterPie = (positions: any) => {
  let array = [];
  if (positions) {
    Object.values(positions).map((item: any) => {
      if (!item.closed_rate || !item.profit) return false;
      array[item?.position_asset.name] = array[item.position_asset.name] ? array[item.position_asset.name] + 1 : 1;
    });
  }

  return array;
};

const dataLineCalculator = (data, date) => {
  const item = data.filter((i) => i[0] === date);
  return item.reduce((n, [key, Amount]) => n + Amount, 0);
};

const margeDataLineChart = (data: any) => {
  const arr = [];
  const result = [["Date", "Profit"]];

  data.map((item, i) => {
    arr[item[0]] = arr[item[0]] ? dataLineCalculator(data, item[0]) : item[1];
  });

  Object.keys(arr).map((item, i) => {
    if (i > 0) {
      result.push([item, arr[item]]);
    }
  });

  return result;
};

const Positions = ({ auth }) => {
  const [positions, setPositions] = useState([]);
  const [pieData, setPieData] = useState([["Market", "Amount"]]);
  const [lineData, setLineData] = useState([["Date", "Profit"]]);

  auth.GetPositions(setPositions);
  const NoData = () => {
    return (
      <tr>
        <td colSpan={6}>{i18n.t("transactions.no_data_to_show")}.</td>
      </tr>
    );
  };

  useEffect(() => {
    if (positions) {
      const data: any = filterPie(positions);
      const resultPie = [["Market", "Amount"]];

      Object.keys(data).map((item, i) => {
        resultPie.push([item, data[item]]);
      });
      if (resultPie.length > 1) {
        setPieData(resultPie);
      }

      const resultLine = [["Date", "Profit"]];
      Object.values(positions).map((item, i) => {
        if (positions[i].profit) {
          resultLine.push([item.date, Number(positions[i].profit)]);
        }
      });
      if (resultLine.length > 1) {
        setLineData(margeDataLineChart(resultLine));
      }
    }
  }, [positions]);

  return (
    <div className="bottom">
      <div className="card">
        <div className="chart-wrapper">
          <Chart
            height={"400px"}
            chartType="PieChart"
            loader={<div>Loading Chart</div>}
            data={pieData}
            options={{
              title: "My Trades",
              colors: ["#33567f", "#3a6190", "#416c9f", "#4674ab", "#4b7db7", "#658dc2", "#8aa2cc", "#a3b5d4", "#b9c6dd", "#ccd5e6"],
              // sliceVisibilityThreshold: 0.2, // 20%
            }}
            rootProps={{ "data-testid": "7" }}
          />

          {/* Option: BarChart / ColumnChart */}
          <Chart
            height={"400px"}
            chartType="Bar"
            loader={<div>Loading Chart</div>}
            data={lineData}
            options={{
              curveType: "function",
              hAxis: {
                title: "Date",
              },
              vAxis: {
                title: "Profit",
              },
            }}
            rootProps={{ "data-testid": "1" }}
          />
        </div>
      </div>

      <div className="card">
        <Table hover responsive>
          <thead>
            <tr>
              <th>{i18n.t("platform.date")}</th>
              <th>{i18n.t("platform.market")}</th>
              <th>{i18n.t("platform.amount")}</th>
              <th>{i18n.t("platform.leverage")}</th>
              <th>{i18n.t("platform.entry")}</th>
              <th>{i18n.t("platform.close")}</th>
              <th>{i18n.t("platform.profit")}</th>
            </tr>
          </thead>
          <tbody>
            {positions.length > 0 ? (
              <>
                {positions.map((position, i) => {
                  if (!position.closed_rate || !position.profit) return false;
                  return (
                    <tr key={i}>
                      <td>{position.date}</td>
                      <td>{position.position_asset.name}</td>
                      <td>{Formating(position.amount)}</td>
                      <td>1:{position.leverage}</td>
                      <td>{Formating(position.open_rate)}</td>
                      <td>{Formating(position.closed_rate)}</td>
                      <td className={`profit ${position.profit < 0 ? "minus" : "plus"}`}>{Formating(position.profit, "€")}</td>
                    </tr>
                  );
                })}
              </>
            ) : (
              <NoData />
            )}
          </tbody>
        </Table>
      </div>
    </div>
  );
};
export default Positions;
