import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import "./Platform.scss";
import { useAuth } from "../../hooks/useAuth";
import Positions from "./Positions/Positions";
import Summary from "./Summary/Summary";
import Referrals from "./Referrals/Referrals";
import { Transactions } from "./Transactions/Transactions";
import { MyAccount } from "./MyAccount/MyAccount";
import { Contracts } from "./Contracts/Contracts";
import { Inbox } from "./Inbox/Inbox";
import { Link } from "react-router-dom";

const Platform = () => {
  const { page } = useParams();
  const auth = useAuth();
  const [currentPage, setCurrentPage] = useState(page);

  useEffect(() => {
    let cleanup = false;
    if (!cleanup) {
      setCurrentPage(page);
    }
    return () => {
      cleanup = true;
    };
  }, [page]);

  auth.checkLogin();
  auth.GetData();

  const menu = [
    { name: "Home", url: "home" },
    { name: "Referrals", url: "referrals" },
    { name: "My Account", url: "my-account" },
    { name: "Transactions", url: "transactions" },
    { name: "Contracts", url: "contracts" },
    { name: "Inbox", url: "inbox" },
  ];

  return (
    <>
      <div className="main">
        <div className="left">
          <ul className="menu">
            {menu.map((item, i) => {
              return (
                <li key={i} className={currentPage === item.url ? "active" : ""}>
                  <Link to={`/platform/${item.url}`}>{item.name}</Link>
                </li>
              );
            })}
          </ul>
        </div>
        <div className="right">
          {currentPage === "home" && (
            <>
              {auth.info && <Summary />}
              {auth.info && <Positions auth={auth} />}
            </>
          )}

          {currentPage === "referrals" && <Referrals auth={auth} />}
          {currentPage === "transactions" && <Transactions />}
          {currentPage === "my-account" && <MyAccount auth={auth} />}
          {currentPage === "contracts" && <Contracts />}
          {currentPage === "inbox" && <Inbox />}
        </div>
      </div>
    </>
  );
};
export default Platform;
