import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { Input } from "../../components/Input";
import i18n from "../../components/i18n";
import { Button, Row, Col } from "react-bootstrap";
import axios from "axios";
import * as Config from "../../config";

const ContactUs = () => {
  const { register, handleSubmit, errors, reset } = useForm();
  const [loadingButton, setLoadingButton] = useState(false);
  const onSubmit = (data) => {
    console.log(data);
    setLoadingButton(true);
    reset();
    setLoadingButton(false);
    axios.post(`${Config.INTEGROMAT_CONTACT}`, data).then((x) => {});
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} noValidate className="contactUs">
      <Row>
        <Col>
          <Input
            name="name"
            type="name"
            reference={register({ required: true })}
            errors={errors}
            title={i18n.t("contactUs.name.label")}
            hideTitle={true}
            placeholder={i18n.t("contactUs.name.placeholder")}
          />
        </Col>

        <Col>
          <Input
            name="phone"
            type="phone"
            reference={register({ required: true })}
            errors={errors}
            title={i18n.t("contactUs.phone.label")}
            hideTitle={true}
            placeholder={i18n.t("contactUs.phone.placeholder")}
          />
        </Col>
        <Col>
          <Input
            name="email"
            type="email"
            reference={register({ required: true })}
            errors={errors}
            title={i18n.t("contactUs.email.label")}
            hideTitle={true}
            placeholder={i18n.t("contactUs.email.placeholder")}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <label className="field">
            <textarea
              name="message"
              ref={register({ required: true })}
              placeholder={i18n.t("contactUs.message.placeholder")}
            ></textarea>
            {errors && errors["message"] && (
              <span className="error">
                {i18n.t("contactUs.message.placeholder")} is required
              </span>
            )}
          </label>
        </Col>
      </Row>
      <div>
        <Button
          className={`button ${loadingButton ? "loading" : ""}`}
          disabled={loadingButton}
          type="submit"
        >
          {i18n.t("contactUs.button")}
        </Button>
      </div>
    </form>
  );
};

export default ContactUs;
