import React from "react";
import NewsTicker from "react-advanced-news-ticker";

export default (props) => {
  
  return <>
      <NewsTicker
        rowHeight={20}
        maxRows={1}
        speed={400}
        duration={2000}
        autoStart={true}
        pauseOnHover={false}
        className={props.class}
      >
        {JSON.parse(props.ticker).map((item, i)=>{
          return <div key={i}>{item}</div>
        })}
      </NewsTicker>
    </>
};