import React, { useState } from "react";
import "./Referrals.scss";
import i18n from "../../../components/i18n";
import { Table } from "react-bootstrap";
import {Formating} from "../../../hooks/helper";

const Referrals = ({ auth }) => {
  const [accounts, setAccounts] = useState(null);
  auth.GetReferrals(setAccounts);

  const clickOnLink = (event) => {
    // console.log(event);
    event.target.select();
  };

  const NoData = () => {
    return (
      <tr>
        <td colSpan={4}>{i18n.t("transactions.no_data_to_show")}.</td>
      </tr>
    );
  };
  return (
    <div>
      <div className="header-platform">
        <h1>Referrals</h1>

        <div className="wrapper-link">
          Referral link
          <input
            className="link"
            defaultValue={`https://www.unitedcapitals.com/?ref=${auth.info.user.uniqid}`}
            onFocus={(e) => clickOnLink(e)}
            onClick={(e) => clickOnLink(e)}
          />
        </div>
      </div>
      <div className="card">
        <Table hover responsive>
          <thead>
            <tr>
              <th>{i18n.t("platform.account")}</th>
              <th>{i18n.t("platform.date")}</th>
              <th>{i18n.t("platform.commission")}</th>
              <th>{i18n.t("platform.status")}</th>
            </tr>
          </thead>
          <tbody>
            {accounts ? (
              <>
                {accounts.map((item, i) => {
                  return (
                    <tr>
                      <td>{item.full_name}</td>
                      <td>{item.created_at}</td>
                      <td>{item.commission > 0 ? Formating(item.commission, '€') : ''}</td>
                      <td></td>
                    </tr>
                  );
                })}
              </>
            ) : (<NoData />)}
          </tbody>
        </Table>


      </div>
    </div>
  );
};

export default Referrals;
