import React from 'react';
import './Payment.scss';
import { useParams } from "react-router-dom";
import { useAuth } from "../../hooks/useAuth";
import { Withdraw } from './Methods/Withdraw';
import { DepositPage } from './Methods/Deposit';

export const Payments = () => {
    const { method } = useParams();
    const auth = useAuth();

    auth.checkLogin();

    return (<>
        <div className="payments sec-pad">
            {method === 'withdraw' && <Withdraw />}
            {method === 'deposit' && <DepositPage />}
        </div>
    </>);
}