import React from "react";
import { Col } from "reactstrap";
import styled from "styled-components";
//
import PageComponents from "../PageComponents";

const ColStyled = styled(Col)`
  h1 {
    font-size: 1.5rem;
  }
  h2 {
    font-size: 1.4rem;
  }
  h3 {
    font-size: 1.3rem;
  }
  h4 {
    font-size: 1.2rem;
  }
  img {
    max-width: 100%;
  }
  button {
    width: 100%;
  }
`;

export default (props) => {
  let sm = 12;
  let md = 12;
  if (props.cols === 2) {
    sm = 6;
    md = 6;
  } else if (props.cols === 3) {
    sm = 6;
    md = 4;
  } else if (props.cols === 4) {
    sm = 6;
    md = 3;
  }
  return (
    <ColStyled xs="12" sm={sm} md={md} className={props.settings.class} id={props.id}>
      <PageComponents components={props.children} />
    </ColStyled>
  );
};
