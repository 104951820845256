import { useState } from "react";
import * as Config from "../config";
import axios from "axios";
import { useAuth } from "./useAuth";
import { useHistory } from "react-router";

// const themeMode = localStorage.getItem("theme-mode") ? localStorage.getItem("theme-mode") : "dark";

export const Formating = (amount, currency?) => {
  if(isNaN(amount)) return amount;
  const showCurrency = !currency ? "" : `${currency} `;

  let maximumFractionDigits;
  if (amount.toString().split(".")[0].replace("-", "") < 10) {
    maximumFractionDigits = 6;
  } else if (amount.toString().split(".")[0].replace("-", "") < 100) {
    maximumFractionDigits = 4;
  } else {
    maximumFractionDigits = 2;
  }
  return showCurrency + new Intl.NumberFormat("us-EN", { currency: "EUR", maximumFractionDigits: maximumFractionDigits }).format(amount);
};

export const GetBalance = () => {
  const [summary, setSummary] = useState({ balance: 0, equity: 0, freeMargin: 0, profit: 0 });
  const [positions, setPositions] = useState({ history: [], open: [] });
  let totalProfit = 0,
    totalAmount = 0,
    equity = 0,
    free_margin = 0,
    profit = 0;

  free_margin = summary.balance - totalAmount;
  profit = summary.profit;
  equity = summary.balance + totalProfit;

  return { summary, totalProfit, totalAmount, positions, equity, free_margin, profit };
};

export const formatDate = (date) => {
  var d = new Date(date),
    month = "" + (d.getMonth() + 1),
    day = "" + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;

  return [year, month, day].join("-");
};

export const useGet = () => {
  const [countries, setCountries] = useState(localStorage.getItem("cou") ? JSON.parse(atob(localStorage.getItem("cou"))) : null);
  const [userInfo, setUserInfo] = useState(localStorage.getItem("uin") ? JSON.parse(atob(localStorage.getItem("uin"))) : null);
  const auth = useAuth();
  let history = useHistory();

  if (!localStorage.getItem("uin")) {
    axios.get("https://api.infoip.io").then((data) => {
      localStorage.setItem("uin", btoa(JSON.stringify(data.data)));
      setUserInfo(data.data);
    });
  }

  if (!localStorage.getItem("cou") && auth.info) {
    axios
      .get(`${Config.ROUTER_HOST}/api/get/countries?token=${auth.info.user.token}`, {
        headers: { Authorization: `Bearer ${auth.info.access_token}` },
      })
      .then((countries) => {
        if (countries.data) {
          localStorage.setItem("cou", btoa(JSON.stringify(countries.data)));
          setCountries(countries.data);
        } else {
          auth.signOut();
        }
      })
      .catch((error) => {
        auth.signOut();
        history.push("/");
      });
  }

  return { countries, userInfo };
};
