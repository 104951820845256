import * as Config from "../config";
import axios from "axios";
import ClientApi from "../api/client";
import { useAuth } from "./useAuth";
import { useGet } from "./helper";
import { parsePhoneNumber } from 'react-phone-number-input'


export const useTransactions = () => {
    const auth = useAuth();
    const get = useGet();

    const getAll = () => {
        return ClientApi.get(`/api/transactions/get/${auth.info.user.token}`, { headers: { "Authorization": `Bearer ${auth.info.access_token}` } });
    }

    const create = (data, setValue, setLoading?, setThankYou?, closeModal?) => {
        let request = data;
        request.token = auth.info.user.token;
        return ClientApi.post(`/api/transactions/create`, request, { headers: { "Authorization": `Bearer ${auth.info.access_token}` } })
            .then(x => {
                if (x.data.data.status === 'success') {

                    if (data.method === '2' || data.method === '6') {  //Bank transfer or withdraw
                        setValue('amount', '');
                        setLoading(false);
                        setThankYou(true);
                        setTimeout(() => {
                            closeModal();
                        }, 1500)
                    }

                    if (data.method === '1' && document.getElementById("cashier")) {    //CreditCard
                        cashier(x, data, setLoading, closeModal);
                    }

                    if (data.method === '3') {  //Crypto
                        bitcoBrokers(x, data, setLoading, closeModal);
                    }

                    if (data.method === '4') {  //Venus
                        venus(setLoading, closeModal);
                    }

                }
            });
    }

    const cashier = (x, data, setLoading, closeModal) => {
        document.getElementById("cashier").innerHTML = "";
        const script = document.createElement('script');
        script.src = Config.CASHIER_URL;
        script.async = true;
        script.setAttribute('data-cashier-key', Config.CASHIER_KEY);
        script.setAttribute('data-cashier-token', '');
        script.setAttribute('data-order-id', x.data.data.transaction_id);
        script.setAttribute('data-currency', 'EUR');
        script.setAttribute('data-direct-payment-method', '');
        script.setAttribute('data-currency-lock', 'true');
        script.setAttribute('data-amount-lock', 'true');
        script.setAttribute('data-first-name', auth.info.user.first_name);
        script.setAttribute('data-last-name', auth.info.user.last_name);
        script.setAttribute('data-amount', data.amount);
        script.setAttribute('data-email', auth.info.user.email);
        script.setAttribute('data-address', auth.info.user.street);
        script.setAttribute('data-country', 'de');
        script.setAttribute('data-state', '');
        script.setAttribute('data-theme', 'dark');
        script.setAttribute('data-city', auth.info.user.city);
        script.setAttribute('data-zip-code', auth.info.user.zip_code);
        script.setAttribute('data-phone', auth.info.user.phone);
        script.setAttribute('data-language', 'en');
        script.setAttribute('data-affiliate-id', '');
        script.setAttribute('data-tracking-id', '');
        script.setAttribute('data-platform-id', '');
        script.setAttribute('data-button-mode', 'modal');
        script.setAttribute('data-button-text', '');
        document.getElementById("cashier")!.appendChild(script);
        setTimeout(() => {
            let button: HTMLElement = document.getElementsByClassName('bp-cashier-button')[0] as HTMLElement;
            clickCashierButton(setLoading, closeModal, button);
        }, 2000);
    }

    const clickCashierButton = (setLoading, closeModal, button) => {
        if (button) {
            button.click();
            setLoading(false);
            closeModal();
        } else {
            clickCashierButton(setLoading, closeModal, button)
        }
    }

    const bitcoBrokers = (x, data, setLoading, closeModal) => {

        const user = auth.info.user;
        let MC = (get.countries) ? get.countries.filter(data => data.id === user.country_id) : '';
        MC = (MC.length > 0) ? MC[0].alpha2 : '';
        const d = new Date(user.birth_date.replace(' 00:00:00', ''));
        const day = String(d.getUTCDate() + 1).padStart(2, '0');
        const month = String(d.getUTCMonth() + 1).padStart(2, '0');
        const birth_date = day + '/' + month + '/' + d.getUTCFullYear();

        const request = [{
            'first_name': user.first_name,
            'last_name': user.last_name,
            'email': user.email,
            'phone': user.phone,
            'gender': 'male',
            'birth': birth_date,
            'country': MC,
            'country_code': parsePhoneNumber(user.phone) ? '+' + parsePhoneNumber(user.phone).countryCallingCode : '+44',
            'state': user.state,
            'city': user.city,
            'address': user.street,
            'zipcode': user.zip_code,
            'currency': 'EUR',
            'trader': {
                'reference': Config.BITCOBROKERS_KEY,
                'callback_url': `${Config.ROUTER_HOST}/api/transaction/update/quamtor`,
                'label': Config.APP_NAME,
                'amount': data.amount,
                'transaction_id': x.data.data.transaction_id
            }
        }];

        return axios.post(Config.BITCOBROKERS_URL, request[0], { headers: { 'Accept': 'application/json' } })
            .then(data => {
                if (data.data.redirect_url) {
                    window.open(data.data.redirect_url, '_blank');
                    if (window.navigator.appVersion.search('Safari') !== -1) {
                        window.location.href = data.data.redirect_url;
                    }
                    setLoading(false);
                    closeModal(false);
                }
            })
    }

    const venus = (setLoading, closeModal) => {
        const url = Config.VENUS_URL;
        window.open(url, '_blank');
        if (window.navigator.appVersion.search('Safari') !== -1) {
            window.location.href = url;
        }
        setLoading(false);
        closeModal(false);

    }

    return { getAll, create }
}