import React, { useState, useEffect } from 'react';
import './ForgotPassword.scss'
import { useForm } from "react-hook-form";
import { useAuth } from '../../hooks/useAuth';
import { Input } from '../../components/Input';
import i18n from '../../components/i18n';
import { useHistory } from 'react-router';
import { Button } from 'react-bootstrap';

const ForgotPassword = () => {
    const auth = useAuth();
    const { register, handleSubmit, errors } = useForm();
    const [loadingButton, setLoadingButton] = useState(false);
    const [error, setError] = useState(false);
    const [sent, setSent] = useState(false);
    let history = useHistory();

    useEffect(() => {
        if (auth.info) {
            history.push('/platform');
        }
    }, [auth.info, history])

    const onSubmit = data => {
        setLoadingButton(true);
        auth.forgotPassword(data, setSent);
        auth.onError.subscribe(x => {
            if (!x.status) {
                setLoadingButton(false);
                setError(true);
            }
        })
    }

    return (
        <>
            <div className="forgot-password thm-container sec-pad">
                {!sent ? <>
                    <h1>{i18n.t('forgot_password.title')}</h1>
                    <h2>{i18n.t('forgot_password.sub_title')}</h2>
                    <form onSubmit={handleSubmit(onSubmit)} noValidate>
                        <Input name="email" type="email" reference={register({ required: true, pattern: { value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i, message: "invalid email address" } })} errors={errors} title={i18n.t('forgot_password.email.label')} placeholder={i18n.t('forgot_password.email.placeholder')} />
                        <div>
                            <Button block className={`button ${loadingButton ? 'loading' : ''}`} disabled={loadingButton} type="submit">{i18n.t('forgot_password.button')}</Button>
                            <div className="error">{error && i18n.t('forgot_password.error_message')}</div>
                        </div>
                    </form>
                    <div className="reCAPTCHA">This site is protected by reCAPTCHA and the Google <a href="https://policies.google.com/privacy" target="_blank" rel="noopener noreferrer">Privacy Policy</a> and <a href="https://policies.google.com/terms" target="_blank" rel="noopener noreferrer">Terms of Service</a> apply.</div>
                </>
                    :
                    <>
                        <h1>{i18n.t('forgot_password.sent_title')}</h1>
                    </>
                }
            </div>

        </>
    );
}

export default ForgotPassword;