import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { Input } from "../../components/Input";
import i18n from "../../components/i18n";
import { Button, Row, Col } from "react-bootstrap";
import axios from "axios";
import * as Config from "../../config";

const QuestionnaireForm = () => {
  const { register, handleSubmit, errors, reset } = useForm();
  const [loadingButton, setLoadingButton] = useState(false);
  const [option1, setOption1] = useState(false);
  const [option2, setOption2] = useState(false);
  const [option3, setOption3] = useState(false);
  const [option4, setOption4] = useState(false);
  const onSubmit = (data) => {
    console.log(data);
    setLoadingButton(true);
    reset();
    setLoadingButton(false);
    axios.post(`${Config.INTEGROMAT_CONTACT}`, data).then((x) => {});
  };

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      noValidate
      className="questionnaireForm"
    >
      <Row>
        <Col>
          <div
            className="title optionTitle"
            onClick={() => setOption1((option1) => !option1)}
          >
            1. What annual returns are you looking for?
          </div>
          <div className={`options ${option1 ? "opened" : "closed"}`}>
            <Input
              name="What-annual-returns-are-you-looking-for"
              type="radio"
              reference={register()}
              title="10% - 25%"
            />
            <Input
              name="What-annual-returns-are-you-looking-for"
              type="radio"
              reference={register()}
              title="25% - 50%"
            />
            <Input
              name="What-annual-returns-are-you-looking-for"
              type="radio"
              reference={register()}
              title="50% - 75%"
            />
          </div>
        </Col>
      </Row>
      <Row>
        <Col>
          <div
            className="title optionTitle"
            onClick={() => setOption2((option2) => !option2)}
          >
            2. Minimum verified track record?
          </div>
          <div className={`options ${option2 ? "opened" : ""}`}>
            <Input
              name="Minimum-verified-track-record"
              type="radio"
              reference={register()}
              title="Less than 12 months"
            />
            <Input
              name="Minimum-verified-track-record"
              type="radio"
              reference={register()}
              title="1 year to 3 years"
            />
            <Input
              name="Minimum-verified-track-record"
              type="radio"
              reference={register()}
              title="More than 3 years"
            />
          </div>
        </Col>
      </Row>
      <Row>
        <Col>
          <div
            className="title optionTitle"
            onClick={() => setOption3((option3) => !option3)}
          >
            3. Available funds for this kind of investment?
          </div>
          <div className={`options ${option3 ? "opened" : ""}`}>
            <Input
              name="Available-funds-for-this-kind-of-investment"
              type="radio"
              reference={register()}
              title="$5.000 - $25.000"
            />
            <Input
              name="Available-funds-for-this-kind-of-investment"
              type="radio"
              reference={register()}
              title="$25.000 - $100.000"
            />
            <Input
              name="Available-funds-for-this-kind-of-investment"
              type="radio"
              reference={register()}
              title="$100.000 - $500.000"
            />
            <Input
              name="Available-funds-for-this-kind-of-investment"
              type="radio"
              reference={register()}
              title="$500.000 >"
            />
          </div>
        </Col>
      </Row>
      <Row>
        <Col>
          <div
            className="title optionTitle"
            onClick={() => setOption4((option4) => !option4)}
          >
            4. Type of investment?
          </div>
          <div className={`options ${option4 ? "opened" : ""}`}>
            <Input
              name="Type-of-investment"
              type="radio"
              reference={register()}
              title="Individual"
            />
            <Input
              name="Type-of-investment"
              type="radio"
              reference={register()}
              title="Corporate"
            />
            <Input
              name="Type-of-investment"
              type="radio"
              reference={register()}
              title="Institutional"
            />
          </div>
        </Col>
      </Row>
      <Row>
        <Col>
          <div className="title title-5">5. Your contact information</div>
          <Input
            name="country"
            type="country"
            reference={register({ required: true })}
            errors={errors}
            title={i18n.t("contactUs.country.label")}
            hideTitle={true}
            placeholder={i18n.t("contactUs.country.placeholder")}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <Input
            name="name"
            type="name"
            reference={register({ required: true })}
            errors={errors}
            title={i18n.t("contactUs.name.label")}
            hideTitle={true}
            placeholder={i18n.t("contactUs.name.placeholder")}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <Input
            name="phone"
            type="phone"
            reference={register({ required: true })}
            errors={errors}
            title={i18n.t("contactUs.phone.label")}
            hideTitle={true}
            placeholder={i18n.t("contactUs.phone.placeholder")}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <Input
            name="email"
            type="email"
            reference={register({ required: true })}
            errors={errors}
            title={i18n.t("contactUs.email.label")}
            hideTitle={true}
            placeholder={i18n.t("contactUs.email.placeholder")}
          />
        </Col>
      </Row>
      <div>
        <Button
          className={`button ${loadingButton ? "loading" : ""}`}
          disabled={loadingButton}
          type="submit"
        >
          {i18n.t("contactUs.button")}
        </Button>
      </div>
    </form>
  );
};

export default QuestionnaireForm;
