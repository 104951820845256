import React, { useState, useEffect } from "react";
import { getPageFromStorage } from "../../cockpit/fetch";
import { useParams } from "react-router-dom";
import PageComponents from "../../components/cockpit/PageComponents";
import * as Config from '../../config';
import './Page.scss';
import Page404 from "./404";

const Page = () => {
    require('../../websites/' + Config.APP_NAME + '/style.scss');
    const [page, setPage] = useState(null);

    const { slug } = useParams();

    useEffect(() => {
        let isCancelled = false;
        getPageFromStorage(slug, (data) => {
            if (!isCancelled) {
                setPage(data);
            }
        })
        return () => {
            isCancelled = true;
        }
    }, [slug])

    return <>
        {page && <>
            {page === 'error' ? <Page404 /> :
                <div className={`page-${page.title.toLowerCase()}`}>
                    <PageComponents components={page.components || []} />
                </div>
            }</>
        }
    </>
}
export default Page;