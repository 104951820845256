import React, { useState } from 'react';
import './Deposit.scss';
import { useForm } from 'react-hook-form';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faSyncAlt, faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import i18n from '../../i18n';
import { Button, Alert } from 'react-bootstrap';
import { useTransactions } from '../../../hooks/useTransactions';
import { Input } from '../../Input';
import * as Config from "../../../config";
import { useAuth } from '../../../hooks/useAuth';
import { Link } from "react-router-dom";


export const Deposit = ({ modalIsVisible, setModalIsVisible, modalType = 'modal' }) => {
    const { register, errors, getValues, setValue, handleSubmit, reset } = useForm();
    const useTransaction = useTransactions();
    const [loading, setLoading] = useState(false);
    const [thankYou, setThankYou] = useState(false);
    const [errorAmount, setErrorAmount] = useState(false);
    const auth = useAuth();

    const closeModal = () => {
        setLoading(false);
        setThankYou(false);
        reset();
        setModalIsVisible(false);
    }
    window.addEventListener('keydown', (event) => {
        if (event.keyCode === 27) {
            closeModal();
        }
    });

    const deposit = () => {
        setThankYou(false);
        setErrorAmount(false);
        const data = getValues();
        if (Number(data.amount) >= Number(Config.MINIMUM_DEPOSIT)) {
            setLoading(true);
            useTransaction.create(data, setValue, setLoading, setThankYou, closeModal);
        } else {
            setErrorAmount(true);
        }
    }

    const methods = {
        2: 'Bank Wire'
    };

    if (Config.CASHIER_KEY && Config.CASHIER_URL) {
        methods[1] = 'Credit Card';
    }

    if (Config.BITCOBROKERS_KEY && Config.BITCOBROKERS_URL) {
        methods[3] = 'Alternative';
    }

    if (Config.VENUS_URL) {
        methods[4] = 'Partner Exchange';
    }

    return (<>
        {modalIsVisible &&
            <>
                {modalType === 'modal' && <div className="deposit-bg" onClick={() => closeModal()}></div>}
                <div className={`deposit-${modalType}`}>
                    <div className="title">
                        {i18n.t('platform.modal.deposit')}
                        {modalType === 'modal' && <span onClick={() => closeModal()}><FontAwesomeIcon icon={faTimes} /></span>}
                    </div>
                    {!auth.missingDetails() &&
                        <form onSubmit={handleSubmit(deposit)}>

                            <ul>
                                {Object.keys(methods).map((item, i) => {
                                    return <li key={i}><label className="radioContainer">
                                        <input type="radio" name="method" value={item} ref={register({ required: true })} defaultChecked={(i === 0) ? true : false} />
                                        <span className="checkmark">{methods[item]}</span>
                                    </label></li>
                                })}
                            </ul>
                            {!thankYou &&
                                <div className="wrapper-input">
                                    <Input name="amount" type="number" reference={register({ required: true })} errors={errors} title={i18n.t('platform.modal.depositAmount.label')} placeholder={i18n.t('platform.modal.depositAmount.placeholder') + Config.MINIMUM_DEPOSIT} />
                                    <div className="error">{errorAmount && `Minimum Deposit € ${Config.MINIMUM_DEPOSIT}`}</div>
                                </div>
                            }
                            {loading && <FontAwesomeIcon icon={faSyncAlt} spin size="lg" className="spinner" />}
                            {thankYou &&
                                <div className="thankYou">
                                    <FontAwesomeIcon icon={faCheckCircle} size="lg" />
                                    {i18n.t('platform.modal.deposit_thank_you')}
                                </div>
                            }
                            <div className="footer">
                                {!thankYou &&
                                    <Button disabled={loading} type="submit">{i18n.t('platform.modal.deposit_button')}</Button>
                                }
                            </div>
                            <div id="cashier"></div>
                        </form>
                    }
                    {auth.missingDetails() &&
                        <>
                            <Alert variant="warning" className="text-center">
                                {i18n.t('platform.modal.depositMissingDetails')} <br />
                                <Link to="/my-account/info">Click here</Link>
                            </Alert>
                        </>
                    }
                </div>
            </>
        }
    </>);
}