import React, { useState, useEffect } from "react";
import "./Transactions.scss";
import { Table } from "react-bootstrap";
import { useParams } from "react-router-dom";
import i18n from "../../../components/i18n";
import { useTransactions } from "../../../hooks/useTransactions";
import { Formating } from "../../../hooks/helper";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlusCircle } from "@fortawesome/free-solid-svg-icons";

export const Transactions = () => {
  const { slug } = useParams();
  const trans = useTransactions();
  const [transactions, setTransactions] = useState([]);
  const subMenu = ["deposits", "withdrawals", "bonuses", "credits"];
  const [swipeIcon, setSwipeIcon] = useState(localStorage.getItem("swipeIcon"));
  const [type, setType] = useState(slug ? slug : "deposits");

  useEffect(() => {
    let isCancelled = false;
    trans.getAll().then((data) => {
      if (!isCancelled) {
        setTransactions(data.data);
      }
    });

    return () => {
      isCancelled = true;
    };
  }, [type]);

  setTimeout(() => {
    setSwipeIcon("false");
    localStorage.setItem("swipeIcon", "false");
  }, 2000);

  const row = (item, i) => {
    i++;
    return (
      <tr key={i}>
        <td>{i}</td>
        <td>{Formating(item.amount, "€")}</td>
        <td>{item.transaction_type.name}</td>
        <td>{item.transaction_status.name}</td>
        <td>{item.transaction_id}</td>
        <td>{item.created_at}</td>
      </tr>
    );
  };

  const noData = () => {
    return (
      <tr>
        <td colSpan={6}>{i18n.t("transactions.no_data_to_show")}.</td>
      </tr>
    );
  };
  const depositStatus = [1, 2, 3, 9];
  const bonusesStatus = [4];
  const creditsStatus = [5];
  const withdrawalsStatus = [6, 7, 8];
  const deposits = transactions.filter((item) => depositStatus.includes(item.transaction_type_id));
  const bonuses = transactions.filter((item) => bonusesStatus.includes(item.transaction_type_id));
  const credits = transactions.filter((item) => creditsStatus.includes(item.transaction_type_id));
  const withdrawals = transactions.filter((item) => withdrawalsStatus.includes(item.transaction_type_id));
  return (
    <>
      <div className="transactions">
        <div className="header-platform">
          <h1>{i18n.t("transactions.title")}</h1>
        </div>

        <div className="wrapper-page">
          <div className="tabs">
            <ul>
              {subMenu.map((item, i) => {
                return (
                  <li key={i} className={`tab ${type === item ? "active" : ""}`} onClick={() => setType(item)}>
                    <Link key={i} to={`/platform/transactions/${item}`} className={type === item ? "active" : ""}>
                      {i18n.t(`transactions.${item}`)}
                    </Link>
                  </li>
                );
              })}
            </ul>
            {type === "withdrawals" && (
              <Link to="/payment/withdraw" className="float-right">
                <FontAwesomeIcon icon={faPlusCircle} />
              </Link>
            )}
            {type === "deposits" && (
              <Link to="/payment/deposit" className="float-right">
                <FontAwesomeIcon icon={faPlusCircle} />
              </Link>
            )}
          </div>
        </div>

        <div className="card">
          <div className="wrapper-table">
            {swipeIcon !== "false" && (
              <div className="swipe-icon">
                <img src="/assets/img/icon-swipe.png" alt="" />
              </div>
            )}
            <Table hover responsive>
              <thead>
                <tr>
                  <th>#</th>
                  <th>{i18n.t("transactions.amount")}</th>
                  <th>{i18n.t("transactions.type")}</th>
                  <th>{i18n.t("transactions.status")}</th>
                  <th>{i18n.t("transactions.number")}</th>
                  <th>{i18n.t("transactions.time")}</th>
                </tr>
              </thead>
              <tbody>
                {type === "deposits" && (deposits.length > 0 ? deposits.map((item, i) => row(item, i)) : noData())}
                {type === "bonuses" && (bonuses.length > 0 ? bonuses.map((item, i) => row(item, i)) : noData())}
                {type === "credits" && (credits.length > 0 ? credits.map((item, i) => row(item, i)) : noData())}
                {type === "withdrawals" && (withdrawals.length > 0 ? withdrawals.map((item, i) => row(item, i)) : noData())}
              </tbody>
            </Table>
          </div>
        </div>
      </div>
    </>
  );
};
