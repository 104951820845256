import React from "react";
import './Home.scss';
import * as Config from "../../config";

const Home = () => {
    const Index = require('../../websites/' + Config.APP_NAME + '/Index').default;
    return <>
        <Index />
    </>
}
export default Home;
