import React, { useState, useEffect } from "react";
import "./Header.scss";
import { useAuth } from "../../hooks/useAuth";
import { Link } from "react-router-dom";
import { useLocation } from "react-router";
import Button from "react-bootstrap/Button";
import { Navbar, Nav } from "react-bootstrap";
import i18n from "../i18n";
import * as Config from "../../config";

const Header = () => {
  const auth = useAuth();
  const [loginStatus, setLoginStatus] = useState(auth.isLoggedIn());
  let location = useLocation();
  const page = location.pathname.replace("/", "").replace("/", "-page ");
  const pageClass = !page ? "home-page" : page + "-page";
  const loginClass = loginStatus ? " logged-in" : "";

  document.body.className = pageClass + loginClass;

  const [scrollPosition, setSrollPosition] = useState(0);
  const handleScroll = () => {
    const position = window.pageYOffset;
    setSrollPosition(position);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll, { passive: true });

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    let isCancelledLoginStatus = false;
    auth.loginStatusChange.subscribe((x) => {
      if (!isCancelledLoginStatus) {
        setLoginStatus(x);
      }
    });
    return () => {
      isCancelledLoginStatus = true;
    };
  }, [auth]);

  const logOut = () => {
    auth.signOut();
  };

  const getMode = () => {
    return localStorage.getItem("theme-mode") ? localStorage.getItem("theme-mode") : "dark";
  };

  const getLogo = () => {
    if (getMode() === "light") {
      return "var(--logo-day)";
    } else {
      return "var(--logo)";
    }
  };

  return (
    <>
      <header className={`header stricky-fixed ${scrollPosition < 50 ? "transparent" : ""}`}>
        {loginStatus && auth.info && (
          <div className="topHeader">
            <div className="thm-container clearfix mx-auto px-0 text-right">
              <span>Hello {auth.info.user.full_name}</span>
              <span>
                Level: <Link to="/account-levels">{auth.info.user.level}</Link>
              </span>
              <span
                className="link"
                onClick={() => {
                  logOut();
                }}
              >
                {i18n.t("menu.logout")}
              </span>
            </div>
          </div>
        )}
        <nav className="navbar navbar-default header-navigation stricky stricky-fixed p-0">
          <div className="thm-container clearfix mx-auto px-0">
            <Navbar variant="dark" expand="lg" collapseOnSelect>
              <Nav.Link as={Link} to={!loginStatus ? "/" : "/platform"} href={!loginStatus ? "/" : "/platform"} className="navbar-brand px-0 d-flex">
                {/* <div className={`logo ${Config.APP_NAME}`} style={{ backgroundImage: getLogo() }}></div> */}
                <svg xmlns="http://www.w3.org/2000/svg" width="250" height="163" viewBox="0 0 685 163" className="logo">
                  <g transform="translate(0 5)">
                    <path
                      d="M-80.3,145.172l-2.872-15.486-12.892,1.983-11.024-28.494s5.978-18.227,6.9-20.846c.712-2.013,2.409-3.7,3.3-6.253a92.448,92.448,0,0,0,3.277-13.5c1.322-7.158,1.925-12.059,6.718-17.023,7.536-7.806,18.337,3.486,19.749,1.562,2.572-3.5,4.51-7.225,4.056-9.02-11.636.515-8.9-6.6-5.926-9.434,7.028-7.051,9.626,3.914,10.214,2.733l7.545-7.7-1.341-5.864s-8.377.929-14.548-1.74c-2.285-.973-1.958-3.145-5.641-4.86,0,0-12.1-1.648-12.357-1.769-3.221-1.5-3.822-8.077-5.949-9.094-.411.876-1.034,5.217-8.908,14.471"
                      transform="matrix(0.985, -0.174, 0.174, 0.985, 258.445, -9.256)"
                      fill="none"
                      stroke="#707070"
                      stroke-width="4"
                    />
                    <path
                      d="M25.823-5.458A31.608,31.608,0,0,1,14.6,6.185,143.9,143.9,0,0,1-4.848,15.4"
                      transform="translate(150 89.762)"
                      fill="none"
                      stroke="#707070"
                      stroke-width="4"
                    />
                    <path
                      d="M-112.255,73.729s-6.455-1.452-10,2.185-4.149,10.717-4.149,10.717c-.282-.021,7.463.658,11.079-2.969s3.071-9.932,3.071-9.932c2.875-2.159,12.74-9.859,15.548-4.405,3.027,5.881-3.338,28.87,1.445,31.071,3.228,1.489,10.151-6.7,12.4-9.009,6.993-7.178,17.907-22.6,22.225-24.458,9.187-3.949,20.626-.439,32.511-2.751,10.108-1.966,15.687-5.544,16.163-5.852,3.528-2.271,13.245-9.274,9-14.5l-.013-.016c-6.34-7.579-18.6,4.949-20,3.036-2.557-3.483-4.484-7.183-4.033-8.968,11.569.512,8.844-6.56,5.892-9.38-6.242-5.961-9.57,3.892-10.155,2.717,0,0-6.881-4.949-7.329-6.68l.379-1.606.955-4.224S-29.118,18.65-22.982,16c2.272-.967,1.774-4.106,5.436-5.811,0,0,12.029-1.639,12.285-1.758C-2.058,6.936-1.78,1.779.334.768c.409.871,2.5,4.392,10.325,13.591C20.919,26.417,33.156,42.826,35.016,59.1c6.639,40.288-24.153,45.971-24.153,45.971"
                      transform="translate(133 0.297)"
                      fill="none"
                      stroke="#1f6ea0"
                      stroke-width="4"
                    />
                    <path
                      d="M2.323.465S2.081,3.866,1.5,7.5c-.415,2.594-2.964,13.279-3.335,14.925L8.85,46.474s11.285-4.2,12.228-4.021,6,15.678,6,15.678"
                      transform="translate(144 90.297)"
                      fill="none"
                      stroke="#1f6ea0"
                      stroke-width="4"
                    />
                    <path
                      d="M18,0s2.192,14.12-2.308,23.87C9.573,35.5,5.414,32.892,1.018,38.262c-1.859,2.271-3.1,8.658-4.525,14.8A116.834,116.834,0,0,1-8.366,68.31"
                      transform="translate(42 80.297)"
                      fill="none"
                      stroke="#1f6ea0"
                      stroke-width="4"
                    />
                    <path
                      d="M2.323.465S2.081,3.866,1.5,7.5C1.427,7.954-.672,21.267-.672,21.267l.147.088a27.33,27.33,0,0,1,6.622,5.9,39.776,39.776,0,0,1,5.829,10.369c4.036,12.442,6.266,14.047,8.765,20.207"
                      transform="translate(120 90.297)"
                      fill="none"
                      stroke="#1f6ea0"
                      stroke-width="4"
                    />
                    <path
                      d="M2.323.465S6.659,11.349-6.1,21.7-29.018,32.63-29.018,32.63l-4.075,10.313h10.328s2.45,6.028,4.949,12.188"
                      transform="translate(88 93.297)"
                      fill="none"
                      stroke="#1f6ea0"
                      stroke-width="4"
                    />
                    <path d="M-1.672,4.95,8.276,0H33.034" transform="translate(87 106.297)" fill="none" stroke="#1f6ea0" stroke-width="4" />
                    <path
                      d="M3,0S2.641,6.292-.628,10.45s-9.449,6.182-9.449,6.182l.946,13.357H3L8.378,41.983"
                      transform="translate(102 106.297)"
                      fill="none"
                      stroke="#1f6ea0"
                      stroke-width="4"
                    />
                    <line x2="238" transform="translate(0 148.297)" fill="none" stroke="#1f6ea0" stroke-width="4" />
                  </g>
                  <text
                    transform="translate(252 73)"
                    fill="#1f6ea0"
                    font-size="80"
                    font-family="Judson-Bold, Judson"
                    font-weight="700"
                    letter-spacing="0.017em"
                  >
                    <tspan x="0" y="0">
                      UNITED
                    </tspan>
                    <tspan fill="#707070">
                      <tspan x="0" y="70">
                        CAPITALS
                      </tspan>
                    </tspan>
                  </text>
                </svg>
              </Nav.Link>
              <Navbar.Toggle aria-controls="responsive-navbar-nav" />

              <Navbar.Collapse id="main-navbar-nav" className="justify-content-end">
                <Nav>
                  {!loginStatus ? (
                    <>
                      <Nav.Link as={Link} to="/" href="/" className="nav-link">
                        <Button variant="link">{i18n.t("menu.home")}</Button>
                      </Nav.Link>
                      <Nav.Link as={Link} to="/about" href="/about" className="nav-link">
                        <Button variant="link">{i18n.t("menu.about")}</Button>
                      </Nav.Link>
                    </>
                  ) : (
                    <>
                      <Nav.Link as={Link} to="/platform" href="/platform" className="nav-link">
                        <Button variant="link">{i18n.t("menu.platform")}</Button>
                      </Nav.Link>
                      <Nav.Link as={Link} to="/platform/my-account" href="/my-account" className="nav-link">
                        <Button variant="link">{i18n.t("menu.myAccount")}</Button>
                      </Nav.Link>
                      <Nav.Link as={Link} to="/platform/transactions" href="/platform/transactions" className="nav-link">
                        <Button variant="link">{i18n.t("menu.transactions")}</Button>
                      </Nav.Link>
                    </>
                  )}

                  {!loginStatus && (
                    <>
                      <Nav.Link as={Link} to="/register" href="/register" className="nav-link">
                        <Button className="rounded-0">{i18n.t("menu.open_account")}</Button>
                      </Nav.Link>
                      <Nav.Link as={Link} to="/login" href="/login" className="nav-link login-botton">
                        <Button className="rounded-0" variant="light">
                          {i18n.t("menu.login")}
                        </Button>
                      </Nav.Link>
                    </>
                  )}
                </Nav>
              </Navbar.Collapse>
            </Navbar>
          </div>
        </nav>
      </header>
    </>
  );
};

export default Header;
